import { Button, Col, Row } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import Error from '../../../components/Error'
import Meta from '../../../components/Layout/Meta'
import Loading from '../../../components/Loading'
import { useStrings } from '../../../hooks/useStrings'
import { useSubUser } from '../../../hooks/SubUser'
import UserInfo from './UserInfo'
import ChargerTagInfo from './ChargerTagInfo'
import PageHeader from '../../../components/Layout/PageHeader'
import DeleteUser from './DeleteUser'

const UpdateAccount = () => {
  const { id } = useParams()
  const { lng } = useStrings('pages', 'UpdateAccount')
  const navigate = useNavigate()

  const { data: subUser, isLoading, isError, error, refetch } = useSubUser(id)

  if (isLoading) return <Loading text={lng('loadingMsg')} />

  if (isError)
    return (
      <Error
        text={error}
        button={{ text: lng('tryAgain'), onClick: refetch }}
      />
    )

  return (
    <div>
      <Meta title={lng('metaTitle')} />
      <PageHeader
        backTo="/accounts"
        pageHeaderProps={{
          title: `${lng('metaTitle')}: ${subUser?.User?.Username}`,
          extra: [
            <Button
              key="welcomeMail"
              type="primary"
              onClick={() => {
                navigate(`/mailtemplate/${id}`)
              }}>
              {lng('sendWelcomeMail')}
            </Button>,
            <DeleteUser key="delete" subUser={subUser} />,
          ],
        }}
      />
      <div className="padding">
        <Row gutter={[32, 0]}>
          <Col span={12}>
            <UserInfo subUser={subUser} />
          </Col>
          <ChargerTagInfo subUser={subUser} />
        </Row>
      </div>
    </div>
  )
}

export default UpdateAccount
