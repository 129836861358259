import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import React from 'react'
// import { useContext } from 'react'
// import { SettingsContext } from '../../../contexts/Settings'
import { useStrings } from '../../../hooks/useStrings'
import CSV from './CSV'
import Excel from './Excel'
// import HSB from './HSB'
// import SBC from './SBC'

const ExportDropdown = props => {
  const { lng } = useStrings('components', 'Table')
  // const { data: settings } = useContext(SettingsContext)

  const items = [
    {
      label: <CSV {...props} />,
      key: 'csv',
      style: { padding: 0 },
    },
    {
      label: <Excel {...props} />,
      key: 'excel',
      style: { padding: 0 },
    },
    // ...(props?.exportTypes?.includes('hsb') &&
    // settings?.ExportSettings?.type === 'hsb'
    //   ? [
    //       {
    //         label: <HSB {...props} />,
    //         key: 'hsb',
    //         style: { padding: 0 },
    //       },
    //     ]
    //   : []),
    // ...(props?.exportTypes?.includes('sbc') &&
    // settings?.ExportSettings?.type === 'sbc'
    //   ? [
    //       {
    //         label: <SBC {...props} />,
    //         key: 'sbc',
    //         style: { padding: 0 },
    //       },
    //     ]
    //   : []),
  ]

  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <Button htmlType="button" type="primary">
        {lng('export')} <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default ExportDropdown
