export const required = {
  required: true,
}

export const emailValidation = {
  type: 'email',
}

export const max = nr => ({
  max: nr,
})

export const min = nr => ({
  nr: nr,
})
