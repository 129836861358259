import { Badge, Card, Col, Row, Statistic } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useChargerConfig, useChargerState } from '../../hooks/Charger'
import { useEventsByCharger } from '../../hooks/Event'
import { useItemWithShares } from '../../hooks/Item'
import { useStrings } from '../../hooks/useStrings'
import { getChargerBadge } from '../../utils/getChargerBadge'

const ChargerStatusWidget = ({
  item,
  handleSetCurrentTotal,
  handleSetLatestTotal,
}) => {
  const { lng } = useStrings('pages', 'Overview')

  const [badgeStatus, setBadgeStatus] = useState({
    message: 'loading',
    color: 'default',
  })
  const [lastChargedBy, setLastChargedBy] = useState(null)

  const { data: auth } = useChargerConfig(item?.id)
  const {
    data: state,
    isLoading: isStateLoading,
    isError: isStateError,
  } = useChargerState(item?.id)

  const {
    data: share,
    isLoading: isShareLoading,
    isError: isShareError,
  } = useItemWithShares(item?.itemId)
  const {
    data: events,
    isLoading: isEventLoading,
    isError: isEventError,
  } = useEventsByCharger(item?.id)

  useEffect(() => {
    if (
      share &&
      !isShareLoading &&
      !isShareError &&
      events &&
      !isEventLoading &&
      !isEventError
    ) {
      if (share?.length === 1) {
        setLastChargedBy(share[0]?.SharedToUsername)
      } else {
        const latestEvent = events?.RfidEvents?.reduce((a, b) => {
          const at = new Date(a?.Timestamp)
          const bt = new Date(b?.Timestamp)

          return at > bt ? a : b
        }, {})

        if (latestEvent?.UserId > -1) {
          const user = share?.find(
            s => s?.SharedToUserId === latestEvent?.UserId,
          )

          setLastChargedBy(user?.SharedToUsername)
        }
      }
    }
  }, [
    share,
    isShareLoading,
    isShareError,
    events,
    isEventLoading,
    isEventError,
  ])

  useEffect(() => {
    if (state && !isStateLoading && !isStateError) {
      const badge = getChargerBadge(state?.ChargerState?.chargerOpMode)

      handleSetCurrentTotal(item?.id, state?.ChargerState?.totalPower || 0)
      handleSetLatestTotal(item?.id, state?.ChargerState?.sessionEnergy || 0)

      setBadgeStatus(badge)
    } else if (isStateError) {
      setBadgeStatus({ message: 'noCar', color: 'default' })
    }
  }, [state, isStateLoading, isStateError])

  return (
    <Card
      title={
        <Link to={`/charger/${item?.id}`}>
          <span>
            {item?.name || item?.id}
            {item?.name && (
              <span style={{ color: 'grey', fontSize: '12px' }}>
                {' '}
                ({item?.id})
              </span>
            )}
          </span>
        </Link>
      }
      bordered={false}
      style={{ width: 300 }}>
      <Row gutter={[8, 16]}>
        <Col span={12}>
          <Statistic
            title={lng('chargingNow')}
            value={state?.ChargerState?.totalPower ?? 0}
            precision={2}
            suffix="kW"
            valueStyle={{
              color: '#1890ff',
              fontSize: '14px',
            }}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={lng('chargingThen')}
            value={state?.ChargerState?.sessionEnergy ?? 0}
            precision={2}
            suffix="kW"
            valueStyle={{
              color: '#1890ff',
              fontSize: '14px',
            }}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={lng('chargingCable')}
            prefix={
              auth?.ChargerConfig?.lockCablePermanently ? (
                <img alt="" height="22" width="22" src="/media/plugOn.png" />
              ) : (
                <img alt="" height="22" width="22" src="/media/plugOff.jpg" />
              )
            }
            value={
              auth?.ChargerConfig?.lockCablePermanently
                ? lng('locked')
                : lng('unlocked')
            }
            valueStyle={{ color: '#1890ff', fontSize: '14px' }}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={lng('accessControl')}
            value={
              auth?.ChargerConfig?.authorizationRequired
                ? lng('rfIdActive')
                : lng('rfIdInactive')
            }
            prefix={
              auth?.ChargerConfig?.authorizationRequired ? (
                <img alt="" height="22" width="22" src="/media/locked.jpg" />
              ) : (
                <img alt="" height="22" width="22" src="/media/unlocked.jpg" />
              )
            }
            valueStyle={{ color: '#1890ff', fontSize: '14px' }}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={lng('chargingStatus')}
            value={lng(badgeStatus?.message)}
            valueStyle={{ color: '#1890ff', fontSize: '14px' }}
            prefix={<Badge status={badgeStatus?.color} />}
          />
        </Col>
        <Col span={12}>
          {state?.ChargerState?.chargerOpMode === 3 && lastChargedBy && (
            <Statistic
              title={lng('chargingBy')}
              value={lastChargedBy}
              className="overviewName"
              valueStyle={{
                color: '#1890ff',
                fontSize: '14px',
              }}
            />
          )}
        </Col>
      </Row>
    </Card>
  )
}

export default ChargerStatusWidget
