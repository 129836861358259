import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { useStrings } from '../../../hooks/useStrings'
import { createCSVData } from '../../../utils/createCSVData'
import * as XLSX from 'xlsx'

const Excel = ({ data, columns, totals, subTable }) => {
  const { lng } = useStrings('components', 'Table')

  const downloadXlsx = e => {
    const excelData = createCSVData({
      data,
      headers: columns,
      childHeaders: subTable?.headers ?? [],
      extra: totals,
    })

    e.preventDefault()
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(wb, ws, 'ChargerSessions')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'ChargerSessions.xlsx')
  }

  return (
    <Button
      onClick={downloadXlsx}
      key="excel"
      htmlType="button"
      type="text"
      icon={<DownloadOutlined />}>
      {lng('exportExcel')}
    </Button>
  )
}

export default Excel
