import { useEffect } from 'react'
import { createContext, useState } from 'react'
import { useItems } from '../hooks/Item'

export const ItemsContext = createContext()

const ItemsProvider = ({ children }) => {
  const { data, isLoading, isError, ...rest } = useItems()
  const [items, setItems] = useState(null)

  useEffect(() => {
    if (!isLoading && !isError) {
      const filterData = data
        ?.reduce((acc, item) => {
          if (item?.ItemSubType === 'EaseeHomeCharger') {
            item?.UserSettings?.easee?.chargers?.map(charger => {
              acc?.push({
                name: item?.Name,
                id: charger?.chargerid,
                itemId: item?.ItemId,
              })
            })
          }
          return acc
        }, [])
        ?.sort((a, b) => a?.name?.localeCompare(b?.name))

      setItems(filterData)
    }
  }, [data, isLoading, isError])

  return (
    <ItemsContext.Provider
      value={{
        data: items,
        isLoading,
        isError,
        ...rest,
      }}>
      {children}
    </ItemsContext.Provider>
  )
}

export default ItemsProvider
