import { PageHeader as AntPageHeader } from 'antd'
import { useNavigate } from 'react-router-dom'

const PageHeader = ({ children, backTo = false, pageHeaderProps = {} }) => {
  const navigate = useNavigate()

  return (
    <div style={{ position: 'sticky', top: 0, zIndex: 100 }}>
      <AntPageHeader
        {...(backTo ? { onBack: () => navigate(backTo) } : {})}
        ghost={false}
        {...pageHeaderProps}>
        {children}
      </AntPageHeader>
    </div>
  )
}

export default PageHeader
