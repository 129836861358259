const messages = [
  'unknown',
  'noCar',
  'carConnected',
  'charging',
  'chargingComplete',
  'errorStatus',
  'readyToCharge',
]

export const getChargerBadge = mode => {
  switch (mode) {
    case 0:
      return { message: messages[0], color: 'warning' }
    case 1:
      return { message: messages[1], color: 'default' }
    case 2:
      return { message: messages[2], color: 'default' }
    case 3:
      return { message: messages[3], color: 'processing' }
    case 4:
      return { message: messages[4], color: 'success' }
    case 5:
      return { message: messages[5], color: 'error' }
    case 6:
      return { message: messages[6], color: 'default' }
    default:
      return undefined
  }
}
