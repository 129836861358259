import { Col } from 'antd'
import { useParams } from 'react-router-dom'
import Error from '../../../components/Error'
import Loading from '../../../components/Loading'
import { useSubUserItems } from '../../../hooks/Item'
import { useStrings } from '../../../hooks/useStrings'
import RfIdCard from './RfIdCard'
import ShareChargerCard from './ShareChargerCard'

const ChargerTagInfo = ({ subUser }) => {
  const { id } = useParams()

  const { lng } = useStrings('pages', 'UpdateAccount')
  const {
    data: userItems,
    isLoading,
    isError,
    error,
    refetch,
  } = useSubUserItems(id)

  if (isLoading) {
    return (
      <Col span={12}>
        <Loading text={lng('loadingMsg')} />
      </Col>
    )
  }

  if (isError) {
    return (
      <Col span={12}>
        <Error
          text={error}
          button={{ text: lng('tryAgain'), onClick: refetch }}
        />
      </Col>
    )
  }

  return (
    <Col span={12}>
      <ShareChargerCard subUser={subUser} userItems={userItems} />

      {/* DONT SHOW RFID UNLESS USER HAS AT LEAST ONE CHARGER  */}
      {id && userItems?.length > 0 ? <RfIdCard /> : null}
    </Col>
  )
}

export default ChargerTagInfo
