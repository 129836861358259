import { Spin } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />

const Loading = ({ text = '' }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Spin tip={text} indicator={antIcon} />
    </div>
  )
}

export default Loading
