import { Form } from 'react-final-form'
import { Form as AntForm } from 'antd'
import { useStrings } from '../../hooks/useStrings'
import { initialValuesForAntD } from '../../utils/initialValuesForAntD'

const CPOForm = ({
  onSubmit = () => {},
  initialValues = {},
  resetOnSubmit = false,
  antFormProps = {},
  children,
}) => {
  const { lng } = useStrings('components', 'CPOForm')
  const [antForm] = AntForm.useForm()

  const validateMessages = {
    required: lng('required'),
  }

  const antInitialValues = initialValuesForAntD(initialValues)

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <AntForm
          form={antForm}
          initialValues={antInitialValues}
          validateMessages={validateMessages}
          layout="vertical"
          {...antFormProps}
          onFinish={
            resetOnSubmit
              ? event => {
                  handleSubmit(event).then(event => {
                    form.reset(event)
                    antForm.resetFields()
                  })
                }
              : handleSubmit
          }>
          {children}
        </AntForm>
      )}
    />
  )
}

export default CPOForm
