import React, { useContext, useEffect, useState } from 'react'
import CPOTable from '../../../components/Table'
import { SettingsContext } from '../../../contexts/Settings'
import { useStrings } from '../../../hooks/useStrings'
import { useUsersEnergy } from '../../../hooks/useUsersEnergy'
import { createTableHeaders } from '../../../utils/createTableHeaders'
import formatUserEnergy from '../../../utils/formatUserEnergy'
import ExpandedRowRender from './ExpandedRowRender'

const KWhPerUserList = ({ values }) => {
  const {
    data: settings,
    isLoading: isSettingsLoading,
    isError: isSettingsError,
  } = useContext(SettingsContext)
  const [
    { data: sessions, isLoading: isSessionsLoading, isError: isSessionsError },
    { data: events, isLoading: isEventsLoading, isError: isEventsError },
    { data: subUsers, isLoading: isSubUsersLoading, isError: isSubUsersError },
    { data: rfIdTags, isLoading: isRfIdTagsLoading, isError: isRfIdError },
  ] = useUsersEnergy(values)
  const { lng } = useStrings('pages', 'ChargeAmount')

  const [chargedByUser, setChargedByUser] = useState()

  const headers = createTableHeaders(
    lng,
    ['username', 'name', 'kwh', 'price'],
    settings,
  )

  const childHeaders = createTableHeaders(
    lng,
    ['name', 'rfId', 'kwh', 'price'],
    settings,
  )

  useEffect(() => {
    if (
      sessions &&
      events &&
      subUsers &&
      rfIdTags &&
      settings &&
      !isSessionsLoading &&
      !isSessionsError &&
      !isEventsLoading &&
      !isEventsError &&
      !isSubUsersLoading &&
      !isSubUsersError &&
      !isRfIdTagsLoading &&
      !isRfIdError &&
      !isSettingsLoading &&
      !isSettingsError
    ) {
      const formattedData = formatUserEnergy(
        {
          RfidEvents: events?.RfidEvents,
          sessions,
          userInfo: subUsers,
          tags: rfIdTags,
        },
        settings,
      )

      setChargedByUser(formattedData)
    }
  }, [
    sessions,
    isSessionsLoading,
    isSessionsError,
    events,
    isEventsLoading,
    isEventsError,
    subUsers,
    isSubUsersLoading,
    isSubUsersError,
    rfIdTags,
    isRfIdTagsLoading,
    isRfIdError,
    settings,
    isSettingsLoading,
    isSettingsError,
  ])

  return (
    <CPOTable
      data={chargedByUser}
      headers={headers}
      subTable={{ headers: childHeaders }}
      // exportTypes={['hsb', 'sbc']}
      summary={{
        keys: ['kwh', 'from', 'to', 'price'],
        date: {
          from: values?.date?.[0].format('YYYY-MM-DD HH:mm:ss'),
          to: values?.date?.[1].format('YYYY-MM-DD HH:mm:ss'),
        },
      }}
      expandable={{
        expandedRowRender: (record, index) => (
          <ExpandedRowRender
            record={record}
            index={index}
            headers={childHeaders}
          />
        ),
      }}
      key="primary"
    />
  )
}

export default KWhPerUserList
