import { Button, Card, Divider, Form, List } from 'antd'
import CPOForm from '../../../../components/Form'
import ItemsInput from '../../../../components/Form/Inputs/ItemsInput'
import { required } from '../../../../components/Form/validations'
import { useShareItem } from '../../../../hooks/Item'
import { useStrings } from '../../../../hooks/useStrings'
import ChargerListItem from './ChargerListItem'

const ShareChargerCard = ({ subUser, userItems }) => {
  const { lng } = useStrings('pages', 'UpdateAccount')

  const { mutate, isLoading: isShareLoading } = useShareItem()

  const onSubmitShare = async values => {
    mutate({
      chargerId: values?.chargerId,
      username: subUser?.User?.Username,
    })
  }

  return (
    <Card title={lng('shareChargers')}>
      <CPOForm onSubmit={onSubmitShare} resetOnSubmit>
        <ItemsInput
          name="chargerId"
          label={lng('chargerPlaceholder')}
          placeholder={lng('chargerPlaceholder')}
          filter={item =>
            !userItems?.some(_item => _item?.itemId === item?.itemId)
          }
          validate={[required]}
          inputProps={{ mode: 'multiple' }}
        />
        <Form.Item>
          <Button
            loading={isShareLoading}
            style={{ float: 'right' }}
            type="primary"
            htmlType="submit">
            {lng('shareChargerButton')}
          </Button>
        </Form.Item>
      </CPOForm>
      <Divider />
      <List
        itemLayout="horizontal"
        dataSource={userItems || []}
        renderItem={item => <ChargerListItem item={item} subUser={subUser} />}
      />
    </Card>
  )
}

export default ShareChargerCard
