import { createContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from '../components/Loading'
import { useLogin } from '../hooks/Auth'
import { useStrings } from '../hooks/useStrings'
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '../utils/storage'

export const EnegicContext = createContext()

const refreshTime = 35 * 1000

const EnegicProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [impersonate, setImpersonate] = useState(false)
  const { lng } = useStrings('components', 'Loading')
  const location = useLocation()
  const navigate = useNavigate()

  const _from = location.state?.from?.pathname || '/'
  const from = _from !== '/login' ? _from : '/'

  const {
    mutateAsync: login,
    isLoading: isLoginLoading,
    error: loginError,
  } = useLogin()

  useQuery(
    ['refetch'],
    async () => {
      let _user = user

      if (!_user) {
        _user = getStorageItem('user')

        if (!_user) {
          return Promise.reject('Not yet logged in')
        }
      }

      const now = new Date()
      const validTo = new Date(_user?.TokenInfo?.ValidTo)

      if (now > validTo) {
        return Promise.reject('Token Expired')
      }

      const timeLeft = validTo.getTime() - now.getTime()

      if (timeLeft > refreshTime) {
        return _user
      }

      const res = await fetch(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/refreshtoken`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: _user?.UserInfo?.username,
            password: _user?.UserInfo?.password,
            ...(impersonate
              ? { Impersonate: _user?.UserInfo?.impersonate }
              : {}),
            token: _user?.TokenInfo?.token,
          }),
        },
      )

      if (!res.ok) {
        return Promise.reject('Refresh failed')
      }

      const data = await res.json()

      _user = {
        ...data,
        UserInfo: {
          ..._user?.UserInfo,
        },
      }
      return _user
    },
    {
      onSuccess: async data => {
        if (!data) {
          logout()
          if (!location?.pathname?.includes('/login')) navigate('/login')
        } else {
          setUser(data)
          // Check other paths
          if (location?.pathname?.includes('/login')) navigate('/')
        }
      },
      onError: () => {
        logout()
        if (!location?.pathname?.includes('/login')) navigate('/login')
      },
      retry: false,
      retryOnMount: false,
      //staleTime: 15 * 60 * 1000,
      cacheTime: 0,
      refetchInterval: 30 * 1000,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      refetchOnReconnect: false,
    },
  )

  const handleLogin = async values => {
    setErrorMsg(null)
    setUser(null)
    await login(values, {
      onSuccess: async data => {
        if (!impersonate) {
          if (
            data?.User?.Rights.some(
              r =>
                r.Right === 'Admin' ||
                r.Right === 'DomainImpersonate' ||
                r.Right === 'DomainAdmin' ||
                r.Right === 'DomainMasterAdmin',
            )
          ) {
            setImpersonate(true)

            return
          }
        }

        if (
          !data?.User?.Capabilities?.some(
            capability => capability.Name === 'CpoBase_V1',
          )
        ) {
          setErrorMsg('User is missing the required license')

          return
        }

        const _user = {
          ...data,
          UserInfo: {
            ...values,
          },
        }

        // Set global user state
        setUser(_user)
        // Set Local Storage
        setStorageItem('user', _user)
        // Navigate to page where user came from
        navigate(from, { replace: true })
      },
      onError: err => {
        if (typeof err === 'string') {
          setErrorMsg(err.message)
        } else {
          setErrorMsg(lng('tryAgain'))
        }
      },
    })
  }

  const logout = () => {
    setUser(null)
    removeStorageItem('user')
    if (!location?.pathname?.includes('/login'))
      navigate('/login', { state: { from: location } })
  }

  const handleImpersonate = impersonate => {
    setImpersonate(impersonate)
  }

  return (
    <EnegicContext.Provider
      value={{
        user,
        error: errorMsg || loginError,
        login: handleLogin,
        logout,
        impersonate,
        handleImpersonate,
      }}>
      {isLoginLoading && (
        <div
          style={{
            height: '100vh',
            width: '100%',
            position: 'absolute',
            zIndex: 100,
            background: 'white',
            top: 0,
          }}>
          <Loading text={lng('loggingIn')} />
        </div>
      )}
      {children}
    </EnegicContext.Provider>
  )
}

export default EnegicProvider
