import { Link } from 'react-router-dom'
import { Avatar } from 'antd'

import { useStrings } from '../../../hooks/useStrings'
import { capitalize } from '../../../utils/capitalize'
import * as Pages from '../../../pages'

const MenuText = ({ page, path, noRoute = false }) => {
  const { lng } = useStrings('pages', page)

  return noRoute ? lng('metaTitle') : <Link to={path}>{lng('metaTitle')}</Link>
}

export const CreateMenu = items => {
  // Create new objs
  const withLabel = items?.map(({ noRoute, ...props }) => ({
    ...props,
    label: <MenuText {...props} noRoute={noRoute} />,
  }))

  // Get child keys
  const childKeys = withLabel?.reduce(
    (acc, { children = [] }) => [...acc, ...children],
    [],
  )

  // Find parents
  const parents = withLabel?.filter(item => !childKeys.includes(item?.key))

  // Recursion function to replace children keys with items
  const createSubMenu = item => {
    if (item?.children?.length > 0) {
      return {
        ...item,
        children: item?.children?.map(key => {
          const childItem = withLabel?.find(_item => _item?.key === key)

          return createSubMenu(childItem)
        }),
      }
    }

    return item
  }

  // Replace keys with item
  const menuItems = parents?.map(item => {
    return createSubMenu(item)
  })

  return menuItems
}

export const CreateRoutes = ({ key, path, page }) => {
  const Component = Pages[page]

  return {
    key,
    path,
    element: <Component key={key} />,
  }
}

export const UserAvatar = ({ user, size = 'default', marginRight = '' }) => {
  if (!user) return null

  return (
    <Avatar
      size={size}
      style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight }}>
      {capitalize(user?.User?.Username?.charAt(0))}
    </Avatar>
  )
}
