import { useMutation, useQuery, useQueryClient } from 'react-query'
import fetchJson from '../utils/fetchJson'
import { useContext } from 'react'
import { EnegicContext } from '../contexts/EnegicUser'
import Message from '../components/Message'
import { handleTypes } from '../components/Error'
import { useStrings } from './useStrings'

export const useLogin = () => {
  return useMutation(async values => {
    return await fetchJson(
      `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/createtoken`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: values?.username,
          password: values?.password,
          ...(values?.impersonate ? { Impersonate: values?.impersonate } : {}),
        }),
      },
    )
  })
}

export const useAuth = () => {
  return useContext(EnegicContext)
}

let adminKey = 'adminInfo'

export const useGetAdminInfo = () => {
  const { user } = useAuth()

  return useQuery(
    [adminKey],
    async () =>
      await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/getuserinfo`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
        },
      ),
    {
      enabled: !!user,
    },
  )
}

export const useUpdateAdminInfo = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { lng } = useStrings('components', 'hookMessages')

  return useMutation(
    async values => {
      return await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/updateuserinfo`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify(values),
        },
      )
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async (_, variables) => {
        await queryClient.refetchQueries([adminKey], {
          active: true,
          exact: true,
        })

        Message(`${lng('updateSuccess')} ${variables?.Email}`, 'success')
      },
    },
  )
}
