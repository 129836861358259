import { Col, Row, Statistic, Tag } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PageHeader from '../../../components/Layout/PageHeader'
import { useChargerState } from '../../../hooks/Charger'
import { useLatestChargingSession } from '../../../hooks/ChargerSessions'
import { useStrings } from '../../../hooks/useStrings'
import { getChargerBadge } from '../../../utils/getChargerBadge'

const ChargerPageHeader = ({ item }) => {
  const { id } = useParams()
  const { lng } = useStrings('pages', 'ChargerId')

  const [badgeStatus, setBadgeStatus] = useState({
    message: 'loading',
    color: 'default',
  })

  const {
    data: state,
    isLoading: isStateLoading,
    isError: isStateError,
  } = useChargerState(id)
  const {
    data: session,
    isLoading: isSessionLoading,
    isError: isSessionError,
  } = useLatestChargingSession(id)

  useEffect(() => {
    if (state && !isStateLoading && !isStateError) {
      const badge = getChargerBadge(state?.ChargerState?.chargerOpMode)
      setBadgeStatus(badge)
    } else if (isStateError) {
      setBadgeStatus({ message: 'noConnection', color: 'error' })
    }
  }, [state, isStateLoading, isStateError])

  return (
    <PageHeader
      backTo="/chargers"
      pageHeaderProps={{
        tags: <Tag color={badgeStatus?.color}>{lng(badgeStatus?.message)}</Tag>,
        title: item?.name || '',
        subTitle: id,
      }}>
      <Row gutter={[0, 32]}>
        <Col span={8}>
          {state && (
            <Statistic
              title={lng('currSession')}
              value={`${(state?.ChargerState?.totalPower ?? 0).toFixed(2)} kWh`}
              valueStyle={{ color: '#0000FF' }}
            />
          )}
        </Col>
        <Col span={8}>
          {state && (
            <Statistic
              title={lng('lastSession')}
              value={`${(state?.ChargerState?.sessionEnergy ?? 0).toFixed(
                2,
              )} kWh`}
            />
          )}
        </Col>
        <Col span={8}>
          {!isSessionError && !isSessionLoading && session ? (
            <Statistic
              title={lng('lastSessionDur')}
              value={`${moment(new Date(session?.Session?.sessionStart)).format(
                'ddd HH:mm',
              )} - ${moment(new Date(session?.Session?.sessionEnd)).format(
                'ddd HH:mm',
              )}`}
            />
          ) : null}
        </Col>
      </Row>
    </PageHeader>
  )
}

export default ChargerPageHeader
