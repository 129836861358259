import CPOTable from '../../../components/Table'

const ExpandedRowRender = ({ record, index, headers }) => {
  return (
    <CPOTable
      data={record?.subTable}
      headers={headers}
      pagination={false}
      key={index}
    />
  )
}

export default ExpandedRowRender
