import { Layout, Menu } from 'antd'
import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { LanguageContext } from '../../contexts/Language'

import { useAuth } from '../../hooks/Auth'
import { TopMenuItems } from './Menus'
import SideMenu from './SideMenu'

const { Header, Content } = Layout

const ProtectedLayout = () => {
  const { user, logout } = useAuth()
  const { lang, setLang } = useContext(LanguageContext)

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {user && <SideMenu />}
      <Layout>
        <Header style={{ padding: '0 24px' }}>
          <Menu
            items={TopMenuItems({ user, lang, setLang, logout })}
            style={{ justifyContent: 'flex-end' }}
            mode="horizontal"
            theme="dark"
          />
        </Header>
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 64px)',
            overflowY: 'scroll',
          }}>
          <Content style={{ minHeight: '100%', height: '1px' }}>
            <Outlet />
          </Content>
        </div>
      </Layout>
    </Layout>
  )
}

export default ProtectedLayout
