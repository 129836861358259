import { DownloadOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import { CSVLink } from 'react-csv'
import { useStrings } from '../../../hooks/useStrings'
import { createCSVData } from '../../../utils/createCSVData'

const CSV = ({ data, columns, totals, subTable }) => {
  const { lng } = useStrings('components', 'Table')

  const excelData = createCSVData({
    data,
    headers: columns,
    childHeaders: subTable?.headers ?? [],
    extra: totals,
  })

  return (
    <Button key="csv" htmlType="button" type="text" icon={<DownloadOutlined />}>
      <Space>
        <CSVLink
          data={excelData}
          filename={'ChargerSessions.csv'}
          target="_blank"
          style={{ color: 'inherit', marginLeft: '8px' }}>
          {lng('exportCSV')}
        </CSVLink>
      </Space>
    </Button>
  )
}

export default CSV
