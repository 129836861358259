import { Card, Divider, List } from 'antd'
import { useParams } from 'react-router-dom'
import { useUserRfIdTags } from '../../../../hooks/RfId'
import { useStrings } from '../../../../hooks/useStrings'
import CreateRfId from './CreateRfId'
import RfIdListItem from './RfIdListItem'

const RfIdCard = () => {
  const { id } = useParams()

  const { lng } = useStrings('pages', 'UpdateAccount')
  const { data: tags, isLoading } = useUserRfIdTags(id)

  return (
    <Card style={{ marginTop: '32px' }} title={lng('addRfIdTags')}>
      <CreateRfId />
      <Divider />
      <List
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={tags || []}
        renderItem={item => <RfIdListItem key={item?.id} item={item} />}
      />
    </Card>
  )
}

export default RfIdCard
