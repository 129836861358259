import { DatePicker, Form } from 'antd'
import moment from 'moment'
import { Field } from 'react-final-form'
import { useStrings } from '../../../hooks/useStrings'

const { RangePicker } = DatePicker

const DateInput = ({
  name,
  label,
  validate = [],
  inputProps = {},
  FormItemProps = {},
}) => {
  const { lng } = useStrings('components', 'DateInput')

  return (
    <Field
      name={name}
      render={({ input }) => (
        <Form.Item
          label={label}
          name={name}
          rules={validate}
          {...FormItemProps}>
          <RangePicker
            {...input}
            {...inputProps}
            ranges={{
              [lng('today')]: [moment().startOf('day'), moment().endOf('day')],
              [lng('thisMonth')]: [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
              [lng('thisYear')]: [
                moment().startOf('year'),
                moment().endOf('year'),
              ],
              [lng('yesterday')]: [
                moment().startOf('day').subtract(1, 'day'),
                moment().endOf('day').subtract(1, 'day'),
              ],
              [lng('lastMonth')]: [
                moment().startOf('month').subtract(1, 'month'),
                moment().endOf('month').subtract(1, 'month'),
              ],
              [lng('lastYear')]: [
                moment().startOf('year').subtract(1, 'year'),
                moment().endOf('year').subtract(1, 'year'),
              ],
            }}
          />
        </Form.Item>
      )}
    />
  )
}

export default DateInput
