import { List } from 'antd'
import { useNavigate } from 'react-router-dom'
import CustomButtonLink from '../../../../components/CustomButtonLink'
import { useUnshareItem } from '../../../../hooks/Item'
import { useStrings } from '../../../../hooks/useStrings'
import AuthTag from './AuthTag'

const ChargerListItem = ({ item, subUser }) => {
  const { lng } = useStrings('pages', 'UpdateAccount')
  const { mutate, isLoading } = useUnshareItem()
  const navigate = useNavigate()

  return (
    <List.Item
      key={item?.id}
      actions={[
        <AuthTag key="auth" id={item?.id} />,
        <CustomButtonLink
          key="edit"
          onClick={() => navigate(`/charger/${item?.id}`)}>
          {lng('edit')}
        </CustomButtonLink>,
        <CustomButtonLink
          loading={isLoading}
          key="remove"
          danger
          onClick={() =>
            mutate({
              itemId: item?.itemId,
              username: subUser?.User?.Username,
            })
          }>
          {lng('remove')}
        </CustomButtonLink>,
      ]}>
      <List.Item.Meta title={item?.name} description={item?.id} />
    </List.Item>
  )
}

export default ChargerListItem
