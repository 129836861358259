import { Alert, Form, Menu, Typography } from 'antd'
import Layout, { Content, Header } from 'antd/lib/layout/layout'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CPOForm from '../../components/Form'
import Meta from '../../components/Layout/Meta'
import { LanguageContext, languages } from '../../contexts/Language'
import { useAuth } from '../../hooks/Auth'
import { useStrings } from '../../hooks/useStrings'
import LoginInput from './LoginInput'

const Login = () => {
  const { lng } = useStrings('pages', 'Login')
  const { login, error, impersonate, handleImpersonate } = useAuth()
  const { lang, setLang } = useContext(LanguageContext)
  const { admin, username } = useParams()

  useEffect(() => {
    if (admin && username) {
      handleImpersonate(true)
    }
  }, [admin, username])

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        backgroundColor: '#11111f',
        backgroundImage: 'url("/media/siteadminlogin.png")',
        backgroundPosition: 'left bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat-x',
      }}>
      <Meta title={lng('metaTitle')} />
      <Layout
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: 'transparent',
          position: 'relative',
        }}>
        <Header
          style={{
            backgroundColor: 'transparent',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            width: '100%',
            height: '140px',
          }}>
          <div style={{ width: '120px', height: '100%' }} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img alt="" src="/media/logo_two.png" />
          </div>
          <div
            style={{
              width: '120px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <div style={{ width: '100%' }}>
              <Menu
                style={{
                  justifyContent: 'flex-end',
                  backgroundColor: 'transparent',
                }}
                theme="dark"
                mode="horizontal"
                items={[
                  {
                    key: 'lang',
                    label: (
                      <span>
                        {lang?.flag || lang?.langLong}{' '}
                        <img alt="" src="/media/arrowdown.png" />
                      </span>
                    ),
                    children: languages?.map(_lang => ({
                      key: _lang?.langShort,
                      label: (
                        <span className="imgHeight">
                          {_lang?.flag} {_lang?.langLong}
                        </span>
                      ),
                      onClick: setLang,
                    })),
                  },
                ]}
              />
            </div>
          </div>
        </Header>
        <Content
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0px 20px',
          }}>
          <div
            className="formContainer"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              // eslint-disable-next-line quotes
              fontFamily: "'IBM Plex Sans', sans-serif",
            }}>
            <Typography.Title
              style={{
                // eslint-disable-next-line quotes
                fontFamily: "'Archivo', sans-serif",
                fontWeight: '500',
                color: 'white',
                fontSize: '48px',
                marginBottom: '80px',
              }}>
              Site Admin Login
            </Typography.Title>
            <CPOForm
              onSubmit={login}
              initialValues={{
                username: admin || '',
                password: '',
                impersonate: username || '',
              }}
              antFormProps={{
                requiredMark: false,
                style: { width: '100%' },
              }}>
              <div>
                <LoginInput
                  name="username"
                  label={lng('usernameLabel')}
                  type="text"
                  required
                />
                <LoginInput
                  name="password"
                  label={lng('passwordLabel')}
                  type="password"
                  required
                />
                {impersonate && (
                  <LoginInput
                    name="impersonate"
                    label={lng('impersonateLabel')}
                    type="text"
                    customColors={{
                      color: '#F6A324',
                      backgroundColor: '#42362E',
                    }}
                  />
                )}
                {error && (
                  <Form.Item>
                    <Alert
                      message={
                        typeof error === 'string'
                          ? error
                          : JSON.stringify(error)
                      }
                      type="error"
                    />
                  </Form.Item>
                )}
              </div>
              <button type="submit" className="loginButton">
                {lng('loginBtn')}
              </button>
            </CPOForm>
          </div>
        </Content>
      </Layout>
    </div>
  )
}

export default Login
