import {
  LockOutlined,
  SyncOutlined,
  UnlockOutlined,
  ExclamationOutlined,
} from '@ant-design/icons'
import { Tag } from 'antd'
import React from 'react'
import { useChargerConfig } from '../../../../hooks/Charger'
import { useStrings } from '../../../../hooks/useStrings'

const AuthTag = ({ id }) => {
  const { lng } = useStrings('pages', 'UpdateAccount')
  const { data, isLoading, isError } = useChargerConfig(id)

  if (isLoading) {
    return (
      <Tag icon={<SyncOutlined spin />} color="blue">
        {lng('loading')}
      </Tag>
    )
  }

  if (isError) {
    return (
      <Tag icon={<ExclamationOutlined />} key="error" color="red">
        Error
      </Tag>
    )
  }

  if (data?.ChargerConfig?.authorizationRequired) {
    return (
      <Tag icon={<LockOutlined />} key="locked" color="blue">
        {lng('rfIdOrApp')}
      </Tag>
    )
  }

  return (
    <Tag icon={<UnlockOutlined />} key="open" color="blue">
      {lng('open')}
    </Tag>
  )
}
export default AuthTag
