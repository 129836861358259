const fetchJson = async (input, init) => {
  const response = await fetch(input, init)

  const contentType = response.headers.get('content-type')

  let data
  if (contentType && contentType.indexOf('application/json') !== -1) {
    data = (await response?.json?.()) ?? {}
  } else {
    data = (await response?.text?.()) ?? ''
  }

  if (response.ok) {
    return data
  }

  throw new FetchError({
    message: data?.Message,
    status: data?.StatusCode,
  })
}

export class FetchError extends Error {
  constructor({ message, status }) {
    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError)
    }

    this.name = 'FetchError'
    this.status = status
  }
}

export default fetchJson
