import './tabs.css'
import { Tabs } from 'antd'

const CPOTabs = ({ tabs = [] }) => {
  const items = tabs.map((tab, i) => ({
    label: tab?.label,
    key: i,
    children: tab?.component,
  }))

  return (
    <div className="card-container">
      <Tabs type="card" defaultActiveKey="0" items={items} />
    </div>
  )
}

export default CPOTabs
