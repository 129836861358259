import { Card, List } from 'antd'
import { useEffect, useState } from 'react'
import { useItemWithShares } from '../../../hooks/Item'
import { useSubUsers } from '../../../hooks/SubUser'
import { useStrings } from '../../../hooks/useStrings'

const UsersCard = ({ item }) => {
  const { lng } = useStrings('pages', 'ChargerId')

  const {
    data: shares,
    isLoading: isSharesLoading,
    isError: isSharesError,
  } = useItemWithShares(item?.itemId)
  const {
    data: subUsers,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useSubUsers()

  const [users, setUsers] = useState([])

  useEffect(() => {
    if (shares && !isSharesLoading && !isSharesError) {
      const _users = subUsers?.filter(user =>
        shares?.some(
          sharedWith => sharedWith?.SharedToUserId === user?.User?.UserId,
        ),
      )

      setUsers(_users)
    }
  }, [
    shares,
    isSharesLoading,
    isSharesError,
    subUsers,
    isUserLoading,
    isUserError,
  ])

  return (
    <Card title={lng('connectedUsers')}>
      <List
        loading={isUserLoading || isSharesLoading}
        size="small"
        dataSource={users ?? []}
        renderItem={item => (
          <List.Item key={item?.User?.UserId}>
            <List.Item.Meta
              title={
                <span>
                  {item?.UserInfo?.FirstName} {item?.UserInfo?.LastName}
                </span>
              }
              description={item?.UserInfo?.Email}
            />
          </List.Item>
        )}
      />
    </Card>
  )
}

export default UsersCard
