import { Button } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Meta from '../../../components/Layout/Meta'
import PageHeader from '../../../components/Layout/PageHeader'
import CPOTable from '../../../components/Table'
import { useStrings } from '../../../hooks/useStrings'
import { createTableHeaders } from '../../../utils/createTableHeaders'
import { useEaseeItems } from './../../../hooks/Item'

const ListChargers = () => {
  const { data, isLoading, isError } = useEaseeItems()
  const { lng } = useStrings('pages', 'ListChargers')
  const navigate = useNavigate()
  const [chargers, setChargers] = useState()

  useEffect(() => {
    if (data && !isLoading && !isError) {
      const formattedChargers = data?.map((u, index) => {
        return {
          key: index,
          itemName: u?.name,
          chargerId: u?.id,
          path: `/charger/${u?.id}`,
        }
      })
      setChargers(formattedChargers)
    }
  }, [isLoading, isError, data])

  const headers = createTableHeaders(lng, ['itemName', 'chargerId', 'actions'])

  return (
    <div>
      <Meta title={lng('metaTitle')} />
      <PageHeader
        pageHeaderProps={{
          title: lng('metaTitle'),
          extra: [
            <Button
              type="primary"
              key="1"
              onClick={() => {
                navigate('/charger/add')
              }}>
              {lng('addCharger')}
            </Button>,
          ],
        }}
      />
      <div className="padding">
        <CPOTable
          loading={isLoading}
          data={chargers}
          headers={headers}
          pagination={{ pageSize: 15 }}
        />
      </div>
    </div>
  )
}

export default ListChargers
