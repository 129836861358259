import { Button, Card, Col, Divider, Form, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import CPOForm from '../../../components/Form'
import StringInput from '../../../components/Form/Inputs/StringInput'
import WhenFieldChanges from '../../../components/Form/Inputs/WhenFieldChanges'
import { emailValidation, required } from '../../../components/Form/validations'
import Meta from '../../../components/Layout/Meta'
import PageHeader from '../../../components/Layout/PageHeader'
import Loading from '../../../components/Loading'
import { useGetAdminInfo } from '../../../hooks/Auth'
import { useCreateSubUser } from '../../../hooks/SubUser'
import { useStrings } from '../../../hooks/useStrings'

const AddAccount = () => {
  const { lng } = useStrings('pages', 'AddAccount')
  const { mutateAsync: createUser, isLoading } = useCreateSubUser()
  const { data: userInfo, isLoading: isUserInfoLoading } = useGetAdminInfo()

  const navigate = useNavigate()

  const onSubmit = async values => {
    const newUser = await createUser(values)

    navigate(`/accounts/${newUser?.User?.UserId}`)
  }

  if (isUserInfoLoading) {
    return <Loading />
  }

  return (
    <div>
      <Meta title={lng('metaTitle')} />
      <PageHeader
        backTo="/accounts"
        pageHeaderProps={{ title: lng('metaTitle') }}
      />
      <div className="padding">
        <Row>
          <Col span={12}>
            <Card title={lng('metaTitle')}>
              <CPOForm
                onSubmit={onSubmit}
                initialValues={{
                  password: process.env.REACT_APP_DEFAUlT_PASSWORD || '',
                  communityName: userInfo?.Organization ?? '',
                  address: userInfo?.Address ?? '',
                  zipCode: userInfo?.ZipCode ?? '',
                  city: userInfo?.City ?? '',
                }}
                resetOnSubmit>
                <WhenFieldChanges field="email" set="username" />
                <StringInput
                  name="email"
                  label={lng('emailLabel')}
                  placeholder="john@doe.com"
                  validate={[emailValidation]}
                />
                <StringInput
                  name="username"
                  label={lng('usernameLabel')}
                  placeholder={lng('usernamePlaceholder')}
                  validate={[required]}
                />
                <StringInput
                  name="password"
                  label={lng('passwordLabel')}
                  placeholder="********"
                  validate={[required]}
                  inputProps={{
                    disabled: true,
                  }}
                />
                <Divider style={{ margin: '40px 0' }} />
                <StringInput
                  name="firstName"
                  label={lng('firstNameLabel')}
                  placeholder="John"
                />
                <StringInput
                  name="lastName"
                  label={lng('lastNameLabel')}
                  placeholder="Doe"
                />
                <StringInput
                  name="phoneNumber"
                  label={lng('phoneLabel')}
                  placeholder="070 123 45 67"
                />
                <StringInput
                  name="apartmentNumber"
                  label={lng('apartmentLabel')}
                  FormItemProps={{
                    tooltip: {
                      title: lng('apartmentTooltip'),
                    },
                  }}
                />
                <StringInput
                  name="address"
                  label={lng('addressLabel')}
                  placeholder="Johndoestreet 1"
                />
                <StringInput
                  name="zipCode"
                  label={lng('zipCodeLabel')}
                  placeholder="111 22"
                />
                <StringInput
                  name="city"
                  label={lng('cityLabel')}
                  placeholder="Stockholm"
                />
                <StringInput
                  FormItemProps={{ noStyle: true }}
                  inputProps={{ type: 'hidden' }}
                  name="communityName"
                  label={lng('communityLabel')}
                />
                <Form.Item>
                  <Button
                    loading={isLoading}
                    style={{ float: 'right' }}
                    type="primary"
                    htmlType="submit">
                    {lng('createUserButton')}
                  </Button>
                </Form.Item>
              </CPOForm>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddAccount
