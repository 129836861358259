/* eslint-disable no-unused-vars */
import { Form } from 'antd'
import { Field, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

const WhenFieldChanges = ({ field, set }) => {
  const AntForm = Form.useFormInstance()

  return (
    <Field name={set} subscription={{ touched: true }}>
      {({ input: { onChange }, meta: { touched } }) => (
        <FormSpy subscription={{}}>
          {({ form }) => (
            <OnChange name={field}>
              {value => {
                if (!touched) {
                  onChange(value)
                  AntForm.setFieldValue(set, value)
                }
              }}
            </OnChange>
          )}
        </FormSpy>
      )}
    </Field>
  )
}

export default WhenFieldChanges
