import { Form, Radio } from 'antd'
import React from 'react'
import { Field } from 'react-final-form'

const Input = ({ input, options }) => {
  const handleOnChange = e => {
    input.onChange(e.target.value)
  }

  return (
    <Radio.Group
      options={options}
      {...input}
      onChange={handleOnChange}
      optionType="button"
    />
  )
}

const RadioInput = ({
  name,
  label,
  options = [],
  validate = [],
  FormItemProps = {},
}) => {
  return (
    <Field
      name={name}
      render={({ input }) => (
        <Form.Item
          label={label}
          name={name}
          rules={validate}
          {...FormItemProps}>
          <Input options={options} input={input} />
        </Form.Item>
      )}
    />
  )
}

export default RadioInput
