import { useContext } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { handleTypes } from '../components/Error'
import Message from '../components/Message'
import { SettingsContext } from '../contexts/Settings'
import fetchJson from '../utils/fetchJson'
import { useAuth } from './Auth'
import { useStrings } from './useStrings'

export const useSettings = () => {
  return useContext(SettingsContext)
}

const settingKey = 'settings'

export const useGetSettings = initialData => {
  const { user } = useAuth()

  return useQuery(
    [settingKey],
    async () =>
      await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/cpo/getsettings`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
        },
      ),
    {
      enabled: !!user,
      initialData,
    },
  )
}

export const useUpdateSettings = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { lng } = useStrings('components', 'hookMessages')

  return useMutation(
    async values => {
      return await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/cpo/upsertsettings`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify({
            PriceSettings: JSON.stringify(values?.PriceSettings),
            ExportSettings: JSON.stringify(values?.ExportSettings),
          }),
        },
      )
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([settingKey], {
          active: true,
          exact: true,
        })

        Message(`${lng('updateSuccess')}`, 'success')
      },
    },
  )
}
