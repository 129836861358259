import { useState, useContext, useEffect, useCallback } from 'react'

import { LanguageContext } from '../contexts/Language'

export const useStrings = (base, componentKey = false) => {
  const { strings } = useContext(LanguageContext)

  const [componentStrings, setComponentStrings] = useState()

  useEffect(() => {
    if (strings) {
      let _componentStrings = {}
      if (componentKey) {
        _componentStrings = strings?.[base]?.[componentKey]
      }

      setComponentStrings({ ..._componentStrings, ...strings?.common })
    }
  }, [strings])

  const lng = useCallback(
    key => {
      if (!key || !componentStrings) return ''

      const string = componentStrings?.[key]
      return string?.t ?? ''
    },
    [componentStrings],
  )

  return { lng, strings: componentStrings }
}
