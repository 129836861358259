import { Button, Card, Form } from 'antd'
import CPOForm from '../../../components/Form'
import StringInput from '../../../components/Form/Inputs/StringInput'
import { required } from '../../../components/Form/validations'
import { useUpdateCharger } from '../../../hooks/Charger'
import { useStrings } from '../../../hooks/useStrings'

const UpdateChargerName = ({ item }) => {
  const { lng } = useStrings('pages', 'ChargerId')
  const { mutateAsync: updateCharger, isLoading } = useUpdateCharger()

  const onSubmit = async values => {
    await updateCharger({ Name: values?.chargerName, ItemId: item?.itemId })
  }

  return (
    <Card title={lng('updateCharger')}>
      <CPOForm
        onSubmit={onSubmit}
        initialValues={{
          chargerName: item?.name,
        }}>
        <StringInput
          name="chargerName"
          label={lng('chargerNameLabel')}
          placeholder="Charger One"
          validate={[required]}
        />
        <Form.Item>
          <Button
            loading={isLoading}
            style={{ float: 'right' }}
            type="primary"
            htmlType="submit">
            {lng('updateCharger')}
          </Button>
        </Form.Item>
      </CPOForm>
    </Card>
  )
}

export default UpdateChargerName
