import { Button, Col, Form, Row } from 'antd'
import Meta from '../../components/Layout/Meta'
import CPOForm from '../../components/Form'
import { useStrings } from '../../hooks/useStrings'
import Loading from '../../components/Loading'
import Error from '../../components/Error'
import PriceSettings from './PriceSettings'
// import ExportSettings from './ExportSettings'
import CPOTabs from '../../components/CPOTabs'
import { useSettings, useUpdateSettings } from '../../hooks/Setting'
import PageHeader from '../../components/Layout/PageHeader'
import AdminSettings from './AdminSettings'

const SettingsFormWrapper = ({ children }) => {
  const { lng } = useStrings('pages', 'Settings')
  const {
    data: settings,
    isLoading,
    isError,
    error,
    refetch,
    isFetched,
  } = useSettings()
  const { mutateAsync: updateSettings, isLoading: isUpdateLoading } =
    useUpdateSettings()

  if (isLoading || !isFetched) return <Loading text={lng('loadingMsg')} />

  if (isError)
    return (
      <Error
        text={error}
        button={{ text: lng('tryAgain'), onClick: refetch }}
      />
    )

  const onSubmit = async values => {
    await updateSettings(values)
  }

  return (
    <CPOForm initialValues={settings} onSubmit={onSubmit}>
      {children}
      <Form.Item>
        <Button
          loading={isUpdateLoading}
          style={{ marginTop: '10px', float: 'right' }}
          type="primary"
          htmlType="submit">
          {lng('saveSettings')}
        </Button>
      </Form.Item>
    </CPOForm>
  )
}

const Settings = () => {
  const { lng } = useStrings('pages', 'Settings')

  const tabs = [
    {
      label: lng('priceSettings'),
      component: (
        <SettingsFormWrapper>
          <PriceSettings />
        </SettingsFormWrapper>
      ),
    },
    // {
    //   label: lng('exportSettings'),
    //   component: (
    //     <SettingsFormWrapper>
    //       <ExportSettings />
    //     </SettingsFormWrapper>
    //   ),
    // },
    {
      label: 'Admin Settings',
      component: <AdminSettings />,
    },
  ]

  return (
    <div>
      <Meta title={lng('metaTitle')} />
      <PageHeader pageHeaderProps={{ title: lng('metaTitle') }} />
      <div className="padding">
        <Row>
          <Col span={12}>
            <CPOTabs tabs={tabs} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Settings
