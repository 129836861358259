import { Button, Popconfirm } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useSubUserItems, useUnshareItem } from '../../../hooks/Item'
import { useDeleteRfIdTag, useUserRfIdTags } from '../../../hooks/RfId'
import { useDeleteSubUser } from '../../../hooks/SubUser'
import { useStrings } from '../../../hooks/useStrings'

const DeleteUser = ({ subUser }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { lng } = useStrings('pages', 'UpdateAccount')

  const { data: tags, isLoading: isTagsLoading } = useUserRfIdTags(id)
  const { data: items, isLoading: isItemsLoading } = useSubUserItems(id)

  const { mutateAsync: deleteUser, isLoading: isDeleteUserLoading } =
    useDeleteSubUser()
  const { mutateAsync: deleteTags, isLoading: isDeleteTagsLoading } =
    useDeleteRfIdTag()
  const { mutateAsync: unshareItem, isLoading: isUnshareLoading } =
    useUnshareItem()

  const handleDelete = async () => {
    if (tags?.length > 0) {
      await deleteTags(
        tags?.reduce((acc, tag) => {
          tag?.ChargerId?.forEach(cid => {
            acc.push({
              Rfid: tag?.Rfid,
              ChargerId: cid,
            })
          })

          return acc
        }, []),
      )
    }

    await Promise.all(
      items?.map(item =>
        unshareItem({
          itemId: item?.itemId,
          username: subUser?.User?.Username,
        }),
      ),
    )

    await deleteUser({
      username: subUser?.User?.Username,
    })

    navigate('/accounts')
  }

  return (
    <Popconfirm
      title={lng('areYouSure')}
      onConfirm={handleDelete}
      okText={lng('yes')}
      cancelText={lng('no')}>
      <Button
        type="danger"
        loading={
          isDeleteTagsLoading ||
          isTagsLoading ||
          isItemsLoading ||
          isUnshareLoading ||
          isDeleteUserLoading
        }>
        {lng('deleteUser')}
      </Button>
    </Popconfirm>
  )
}

export default DeleteUser
