import { Descriptions } from 'antd'
import { useContext, useMemo } from 'react'
import { useStrings } from '../../hooks/useStrings'

import ExportDropdown from './ExportButtons'
import { SettingsContext } from '../../contexts/Settings'

const Summary = ({ data, columns, summary, subTable, exportTypes = [] }) => {
  const { lng } = useStrings('components', 'Table')
  const { data: settings } = useContext(SettingsContext)

  const totalKwh = lng('totalKWH')
  const to = lng('to')
  const from = lng('from')

  const totals = useMemo(() => {
    const newTotals = summary?.keys?.map(key => {
      let totalByKey = data?.reduce((acc, row) => acc + Number(row?.[key]), 0)

      switch (key) {
        case 'price':
          return [
            lng('totalPrice'),
            `${
              settings?.PriceSettings?.currencyFix === 'prefix'
                ? ` ${settings?.PriceSettings?.currency}`
                : ''
            }${totalByKey?.toFixed(2).toString().replace('.', ',')}${
              settings?.PriceSettings?.currencyFix === 'suffix'
                ? ` ${settings?.PriceSettings?.currency}`
                : ''
            }`,
          ]
        case 'kwh':
          return [totalKwh, totalByKey?.toFixed(6)?.replace('.', ',')]
        case 'to':
          return [to, summary?.date?.to]
        case 'from':
          return [from, summary?.date?.from]
        default:
          return ['Total', totalByKey]
      }
    })

    return newTotals || []
  }, [summary.keys, settings, totalKwh, to, from])

  return (
    <Descriptions
      title={lng('summaryTitle')}
      size="small"
      column={4}
      extra={
        <ExportDropdown
          data={data}
          columns={columns}
          subTable={subTable}
          totals={totals}
          exportTypes={exportTypes}
          summary={summary}
        />
      }>
      {totals?.map((total, i) => (
        <Descriptions.Item key={i} label={total[0]}>
          {total[1]}
        </Descriptions.Item>
      ))}
    </Descriptions>
  )
}

export default Summary
