import { Button } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Meta from '../../../components/Layout/Meta'
import PageHeader from '../../../components/Layout/PageHeader'
import CPOTable from '../../../components/Table'
import { useSubUsers } from '../../../hooks/SubUser'
import { useStrings } from '../../../hooks/useStrings'
import { createTableHeaders } from '../../../utils/createTableHeaders'

const ListAccounts = () => {
  const [users, setUsers] = useState()

  const { lng } = useStrings('pages', 'Accounts')
  const { data, isLoading, isError } = useSubUsers()

  useEffect(() => {
    if (data && !isLoading && !isError) {
      const formattedUsers = data?.map((u, index) => {
        return {
          key: index,
          id: u.User.UserId,
          username: u.User.Username,
          email: u.UserInfo.Email,
          firstName: u.UserInfo.FirstName,
          lastName: u.UserInfo.LastName,
          path: `/accounts/${u?.User?.UserId}`,
        }
      })

      setUsers(formattedUsers)
    }
  }, [isLoading, isError, data])

  const navigate = useNavigate()

  const headers = createTableHeaders(lng, [
    'username',
    'email',
    'firstName',
    'lastName',
    'actions',
  ])

  return (
    <div>
      <Meta title={lng('metaTitle')} />

      <PageHeader
        pageHeaderProps={{
          title: lng('metaTitle'),
          extra: [
            <Button
              type="primary"
              key="1"
              onClick={() => {
                navigate('/account/add')
              }}>
              {lng('createAccount')}
            </Button>,
          ],
        }}
      />
      <div className="padding">
        <CPOTable
          loading={isLoading}
          data={users}
          headers={headers}
          pagination={{ pageSize: 15 }}
        />
      </div>
    </div>
  )
}

export default ListAccounts
