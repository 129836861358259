import { useContext, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { handleTypes } from '../components/Error'
import Message from '../components/Message'
import { ItemsContext } from '../contexts/Items'
import fetchJson from '../utils/fetchJson'

import { useAuth } from './Auth'
import { useStrings } from './useStrings'

export const useItems = () => {
  const { user } = useAuth()

  return useQuery(
    'items',
    async () =>
      await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/listitems`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify({ IncludeDeletedItems: false }),
        },
      ),
    {
      enabled: !!user,
    },
  )
}

export const useEaseeItems = () => {
  return useContext(ItemsContext)
}

export const sharedItemsKey = 'itemsShares'

export const fetchItemsWithShares = (items, user) => {
  return async () => {
    let itemsShares = []
    for (const item of items) {
      const data = await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/getitemshares`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify({
            ItemId: item?.itemId,
          }),
        },
      )
      itemsShares.push({
        ...item,
        sharedWith: data,
      })
    }

    return itemsShares
  }
}

// Should be replaced with Get User Items
export const useItemsWithShares = () => {
  const { user } = useAuth()
  const { data: items } = useEaseeItems()

  return useQuery(sharedItemsKey, fetchItemsWithShares(items, user), {
    enabled: !!items && !!user,
  })
}

export const useItemWithShares = id => {
  const { user } = useAuth()

  return useQuery(
    [sharedItemsKey, id],
    async () =>
      await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/getitemshares`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify({
            ItemId: id,
          }),
        },
      ),
    {
      enabled: !!id && !!user,
    },
  )
}

export const useItemWithSharesByChargerId = id => {
  const { data: items } = useEaseeItems()

  const item = items?.find(item => item?.id === id)

  return useItemWithShares(item?.itemId)
}

export const useShareItem = () => {
  const { user } = useAuth()
  const { data: items } = useEaseeItems()
  const queryClient = useQueryClient()
  const { lng } = useStrings('components', 'hookMessages')

  return useMutation(
    async values => {
      const shares = values?.chargerId?.map(charger => {
        const item = items?.find(item => item?.id === charger)

        return fetchJson(
          `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/shareitem`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'X-Authorization': user?.TokenInfo?.Token,
            },
            body: JSON.stringify({
              ItemId: item?.itemId,
              ShareToUser: values?.username,
            }),
          },
        )
      })

      return await Promise.all(shares)
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async (_, variables) => {
        await queryClient.refetchQueries(sharedItemsKey, {
          active: true,
          exact: true,
        })

        Message(`${lng('updateSuccess')} ${variables?.chargerId}`, 'success')
      },
    },
  )
}

export const useUnshareItem = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { lng } = useStrings('components', 'hookMessages')

  return useMutation(
    async values => {
      return await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/unshareitem`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify({
            ItemId: values?.itemId,
            SharedToUser: values?.username,
          }),
        },
      )
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async (_, variables) => {
        await queryClient.refetchQueries(sharedItemsKey, {
          active: true,
          exact: true,
        })

        Message(`${lng('unsharedFrom')} ${variables?.username}`, 'success')
      },
    },
  )
}

export const useSubUserItems = id => {
  const { data: items, isLoading, isError, ...rest } = useItemsWithShares()

  const data = useMemo(() => {
    if (items && !isLoading && !isError) {
      const filteredItems = items?.filter(item => {
        return item?.sharedWith?.some(
          subUser => subUser?.SharedToUserId === Number(id),
        )
      })

      return filteredItems
    }
  }, [items, isLoading, isError])

  return { data, isLoading, isError, ...rest }
}
