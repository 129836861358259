import { Field } from 'react-final-form'

const requiredFunc = value => (value ? undefined : 'Required')

const LoginInput = ({
  label,
  name,
  type = 'text',
  required = false,
  customColors = false,
}) => {
  return (
    <Field
      name={name}
      validate={required ? requiredFunc : null}
      render={({ input, meta }) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              color: customColors ? customColors?.color : '#F1F4FA',
              fontSize: '18px',
              marginBottom: '32px',
              position: 'relative',
            }}>
            <label style={{ fontSize: '18px', marginBottom: '8px' }}>
              {label}
            </label>
            <input
              type={type}
              className={customColors ? 'inputCustomColors' : 'loginInput'}
              style={{
                backgroundColor: customColors
                  ? customColors?.backgroundColor
                  : '#222330',
                height: '50px',
                borderRadius: '6px',
                padding: '13px 16px',
                ...(meta?.error && meta?.touched
                  ? { borderColor: '#E3633D' }
                  : meta?.active
                  ? { borderColor: '#13E197' }
                  : {}),
              }}
              {...input}
              autoComplete={input.name}
            />
            {meta?.error && meta?.touched ? (
              <span
                style={{
                  fontSize: '12px',
                  color: '#E3633D',
                  position: 'absolute',
                  bottom: '-26px',
                }}>
                {meta?.error}
              </span>
            ) : null}
          </div>
        )
      }}
    />
  )
}

export default LoginInput
