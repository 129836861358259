import { createContext } from 'react'
import { useGetSettings } from '../hooks/Setting'

export const SettingsContext = createContext()

const defaultSettings = {
  PriceSettings: {
    currency: 'kr',
    currencyFix: 'suffix',
    price: '0',
  },
  ExportSettings: {
    type: '',
    hsbRegionNumber: '',
    hsbCooperativeNumber: '',
    sbcId: '',
  },
}

const SettingsProvider = ({ children }) => {
  const response = useGetSettings(defaultSettings)

  return (
    <SettingsContext.Provider
      value={{
        ...response,
      }}>
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsProvider
