import { Form, Select } from 'antd'
import { useField } from 'react-final-form'
import { useEaseeItems } from '../../../hooks/Item'
import { useStrings } from '../../../hooks/useStrings'

import Error from '../../Error'

const ItemsInput = ({
  name,
  label,
  placeholder,
  validate = [],
  inputProps = {},
  FormItemProps = {},
  filter = item => item,
}) => {
  const { input } = useField(name)
  const { data: items, isLoading, isError, error, refetch } = useEaseeItems()
  const { lng } = useStrings('components', 'ItemsInput')
  const AntForm = Form.useFormInstance()

  const handleOnChange = value => {
    if (value?.includes('all')) {
      const all = items?.filter(filter)?.map(item => item?.id)
      input?.onChange(all)
      AntForm.setFieldValue(name, all)
    } else {
      input?.onChange(value)
    }
  }

  return (
    <Form.Item label={label} name={name} rules={validate} {...FormItemProps}>
      <Select
        loading={isLoading}
        notFoundContent={
          isError ? (
            <Error
              text={error}
              button={{ text: lng('tryAgain'), onClick: refetch }}
            />
          ) : items?.filter(filter)?.length <= 0 ? (
            <Error text={lng('noItems')} />
          ) : null
        }
        placeholder={placeholder}
        value={input?.value}
        onChange={handleOnChange}
        {...inputProps}>
        {inputProps?.mode === 'multiple' && (
          <Select.Option key="all" value="all">
            {lng('shareAll')}
          </Select.Option>
        )}
        {items?.filter(filter)?.map(
          (
            item,
            i, // TODO: SORT + SEARCH
          ) => (
            <Select.Option value={item?.id} key={i}>
              <span>
                {item?.name}{' '}
                <span style={{ color: '#bfbfbf', fontSize: '12px' }}>
                  ({item?.id})
                </span>
              </span>
            </Select.Option>
          ),
        )}
      </Select>
    </Form.Item>
  )
}

export default ItemsInput
