import { Form, Input } from 'antd'
import { Field } from 'react-final-form'

const CPOInput = ({ inputType, ...rest }) => {
  switch (inputType) {
    case 'password':
      return <Input.Password {...rest} />
    default:
      return <Input {...rest} />
  }
}

const StringInput = ({
  name,
  label,
  placeholder,
  type = 'default',
  validate = [],
  inputProps = {},
  FormItemProps = {},
}) => {
  return (
    <Field
      name={name}
      render={({ input }) => (
        <Form.Item
          label={label}
          name={name}
          rules={validate}
          {...FormItemProps}>
          <CPOInput
            {...input}
            inputType={type}
            placeholder={placeholder}
            autoComplete={`new-${name}`}
            {...inputProps}
          />
        </Form.Item>
      )}
    />
  )
}

export default StringInput
