import { Helmet } from 'react-helmet-async'

const Meta = ({ title }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{title} - Site Admin Enegic</title>
    <link
      rel="icon"
      type="image/png"
      href="/media/logo_small.png"
      sizes="16x16"
    />
  </Helmet>
)

export default Meta
