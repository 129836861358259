import { Button } from 'antd'

const CustomButtonLink = ({ onClick, children, ...rest }) => {
  return (
    <Button onClick={onClick} {...rest} type="link" size="small">
      {children}
    </Button>
  )
}

export default CustomButtonLink
