import { useMutation, useQuery, useQueryClient } from 'react-query'
import { handleTypes } from '../components/Error'
import Message from '../components/Message'
import fetchJson from '../utils/fetchJson'
import { useAuth } from './Auth'
import { useStrings } from './useStrings'

export const subUserKey = 'subUser'

export const useSubUser = id => {
  const { user } = useAuth()

  return useQuery(
    [subUserKey, id],
    async () =>
      await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/getsubuser`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify({
            UserId: id,
          }),
        },
      ),
    {
      enabled: !!id && !!user,
    },
  )
}

export const fetchSubUsers = user => {
  return async () =>
    await fetchJson(
      `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/getsubusers`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization': user?.TokenInfo?.Token,
        },
      },
    )
}

export const useSubUsers = () => {
  const { user } = useAuth()

  return useQuery([`${subUserKey}s`], fetchSubUsers(user))
}

export const useUpdateSubUser = id => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { lng } = useStrings('components', 'hookMessages')

  return useMutation(
    async values => {
      const communityName = values?.communityName?.trim() || ''
      const apartmentNumber = values?.apartmentNumber?.trim() || ''

      return await Promise.all([
        fetchJson(
          `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/updatesubuser`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'X-Authorization': user?.TokenInfo?.Token,
            },
            body: JSON.stringify({
              Email: values?.email?.trim?.() ?? '',
              FirstName: values?.firstName?.trim?.() ?? '',
              LastName: values?.lastName?.trim?.() ?? '',
              Address: values?.address?.trim?.() ?? '',
              ZipCode: values?.zipCode?.trim?.() ?? '',
              City: values?.city?.trim?.() ?? '',
              PhoneNumber: values?.phoneNumber?.trim?.() ?? '',
              ...(communityName || apartmentNumber
                ? {
                    Notes: JSON.stringify({
                      ...(communityName
                        ? { CommunityName: communityName }
                        : {}),
                      ...(apartmentNumber
                        ? { ApartmentNumber: apartmentNumber }
                        : {}),
                    }),
                  }
                : {}),
              UserId: id,
            }),
          },
        ),
        fetchJson(
          `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/updateusername`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Authorization': user?.TokenInfo?.Token,
            },
            body: JSON.stringify({
              UserId: values?.id,
              NewUsername: values?.username?.trim(),
            }),
          },
        ),
      ])
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async data => {
        await queryClient.refetchQueries([subUserKey, id], {
          active: true,
          exact: true,
        })

        Message(
          `${lng('updateSuccess')} ${data?.[0]?.User?.Username}`,
          'success',
        )
      },
    },
  )
}

export const useCreateSubUser = () => {
  const { lng } = useStrings('components', 'hookMessages')
  const { user } = useAuth()

  return useMutation(
    async values => {
      const communityName = values?.communityName?.trim() || ''
      const apartmentNumber = values?.apartmentNumber?.trim() || ''

      return await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/createsubuser`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify({
            Username: values.username.trim(),
            Password: values.password.trim(),
            Email: values?.email?.trim?.() ?? '',
            FirstName: values?.firstName?.trim?.() ?? '',
            LastName: values?.lastName?.trim?.() ?? '',
            Address: values?.address?.trim?.() ?? '',
            ZipCode: values?.zipCode?.trim?.() ?? '',
            City: values?.city?.trim?.() ?? '',
            PhoneNumber: values?.phoneNumber?.trim?.() ?? '',
            ...(communityName || apartmentNumber
              ? {
                  Notes: JSON.stringify({
                    ...(communityName ? { CommunityName: communityName } : {}),
                    ...(apartmentNumber
                      ? { ApartmentNumber: apartmentNumber }
                      : {}),
                  }),
                }
              : {}),
          }),
        },
      )
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async data => {
        Message(`${lng('createSuccess')} ${data?.User?.Username}`, 'success')
      },
    },
  )
}

export const useDeleteSubUser = () => {
  const { lng } = useStrings('components', 'hookMessages')
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation(
    async values => {
      return await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/deleteuser`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify({
            Username: values?.username,
          }),
        },
      )
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async (_, variables) => {
        await queryClient.refetchQueries([`${subUserKey}s`], {
          active: true,
          exact: true,
        })

        Message(`${lng('deleteSuccess')} ${variables?.username}`, 'success')
      },
    },
  )
}
