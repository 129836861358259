import { Button, Empty } from 'antd'

const translateFetchErrorMessage = (message /* lng */) => {
  switch (message) {
    case 'Something went horribly, horribly wrong while servicing your request.':
      return message // Translate
    default:
      return message
  }
}

const handleObjectName = (text, lng) => {
  switch (text?.name) {
    case 'FetchError':
      return translateFetchErrorMessage(text?.message, lng)
    case 'SyntaxError':
      return 'Something went wrong: SyntaxError'
    default:
      return 'Something went wrong: Error object unknown.'
  }
}

export const handleTypes = (text, lng) => {
  switch (typeof text) {
    case 'string':
      return text
    case 'object':
      return handleObjectName(text, lng)
    default:
      return 'Something went wrong: Error type unknown.'
  }
}

const Error = ({ text = '', button = false }) => {
  const _text = handleTypes(text) // Pass in lng

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Empty description={<span>{_text}</span>}>
        {button && (
          <Button type="primary" onClick={button?.onClick}>
            {button?.text}
          </Button>
        )}
      </Empty>
    </div>
  )
}

export default Error
