import { LogoutOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { useState } from 'react'
import { useAuth } from '../../hooks/Auth'
import { useStrings } from '../../hooks/useStrings'
import { SiderMenuItems } from './Menus'

const { Sider } = Layout

const SideMenu = () => {
  const { lng } = useStrings('components', 'Layout')
  const { logout } = useAuth()
  const [collapsed, setCollapsed] = useState(false)
  const [current, setCurrent] = useState('overview')

  const onCollapse = collapsed => {
    setCollapsed(collapsed)
  }

  const onMenuClick = e => {
    setCurrent(e.key)
  }

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      theme="light">
      <div style={{ height: '60px', padding: '12px 24px' }}>
        <img
          style={{ height: '100%', width: '100%', objectFit: 'contain' }}
          alt=""
          src={collapsed ? '/media/logo_small.png' : '/media/logo.png'}
        />
      </div>
      <Menu
        items={[
          ...SiderMenuItems,
          {
            key: 'logout',
            icon: <LogoutOutlined />,
            style: { display: 'flex', alignItems: 'center' },
            onClick: logout,
            label: lng('logout'),
          },
        ]}
        mode="vertical"
        onClick={onMenuClick}
        selectedKeys={[current]}
      />
    </Sider>
  )
}

export default SideMenu
