import { List, Tag } from 'antd'
import CustomButtonLink from '../../../../components/CustomButtonLink'
import { useDeleteRfIdTag } from '../../../../hooks/RfId'
import { useStrings } from '../../../../hooks/useStrings'

const RfIdListItem = ({ item }) => {
  const { mutateAsync: deleteTag, isLoading } = useDeleteRfIdTag()

  const { lng } = useStrings('pages', 'UpdateAccount')

  const handleDeleteTag = async () => {
    await deleteTag(
      item?.ChargerId?.map(id => ({
        Rfid: item?.Rfid,
        ChargerId: id,
      })),
    )
  }

  return (
    <List.Item
      actions={[
        ...(Array.isArray(item.ChargerId)
          ? item.ChargerId.map((chargerId, i) => (
              <Tag key={`chargerId:${i}`} color="blue">
                {chargerId}
              </Tag>
            ))
          : [
              <Tag key={`chargerId:${item?.ChargerId}`} color="blue">
                {item?.ChargerId}
              </Tag>,
            ]),
        <CustomButtonLink
          loading={isLoading}
          key="remove"
          danger
          onClick={handleDeleteTag}>
          {lng('remove')}
        </CustomButtonLink>,
      ]}>
      <List.Item.Meta title={item?.Name} description={item?.Rfid} />
    </List.Item>
  )
}

export default RfIdListItem
