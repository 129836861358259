import { Button, Form } from 'antd'
import moment from 'moment'
import { useState } from 'react'

import CPOForm from '../../../components/Form'
import DateInput from '../../../components/Form/Inputs/DateInput'
import { required } from '../../../components/Form/validations'
import { useStrings } from '../../../hooks/useStrings'
import KWhPerUserList from './List'

const FlexParking = () => {
  const { lng } = useStrings('pages', 'FlexParking')
  const [submittedValues, setSubmittedValues] = useState(null)

  const onSubmit = async values => {
    setSubmittedValues(values)
  }

  return (
    <div className="padding">
      <CPOForm onSubmit={onSubmit}>
        <div style={{ width: '400px' }}>
          <DateInput
            name="date"
            label={lng('timeRangeLabel')}
            validate={[required]}
            inputProps={{
              style: { width: '100%' },
              showTime: {
                defaultValue: [
                  moment('00:00:00', 'HH:mm:ss'),
                  moment('00:00:00', 'HH:mm:ss'),
                ],
                format: 'HH',
              },
            }}
          />
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {lng('generateBtn')}
          </Button>
        </Form.Item>
      </CPOForm>
      <div>
        {submittedValues && <KWhPerUserList values={submittedValues} />}
      </div>
    </div>
  )
}

export default FlexParking
