import CryptoJS from 'crypto-js'

export const setStorageItem = (key, value) => {
  const cipherText = CryptoJS.AES.encrypt(
    JSON.stringify(value),
    process.env.REACT_APP_STORAGE_SECRET_KEY,
  ).toString()

  localStorage.setItem(key, cipherText)
}

export const getStorageItem = key => {
  const cipherText = localStorage.getItem(key)

  if (!cipherText) return null

  const bytes = CryptoJS.AES.decrypt(
    cipherText,
    process.env.REACT_APP_STORAGE_SECRET_KEY,
  )

  const value = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

  return value
}

export const removeStorageItem = key => {
  localStorage.removeItem(key)
}
