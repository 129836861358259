import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

const ButtonWrapper = ({ record, children }) => {
  const navigate = useNavigate()

  return (
    <Button
      onClick={() => navigate(`${record.path}`)}
      type="primary"
      size="small">
      {children}
    </Button>
  )
}

export const createTableHeaders = (lng, keys, settings = {}) => {
  const PriceRender = (text, _, index) => (
    <span key={index}>{`${
      settings?.PriceSettings?.currencyFix === 'prefix'
        ? ` ${settings?.PriceSettings?.currency}`
        : ''
    }${Number(text)?.toFixed(2).toString().replace('.', ',')}${
      settings?.PriceSettings?.currencyFix === 'suffix'
        ? ` ${settings?.PriceSettings?.currency}`
        : ''
    }`}</span>
  )
  const KwhRender = (text, _, index) => (
    <span key={index}>{Number(text)?.toFixed(6).toString()}</span>
  )

  const ActionRender = (_, record, index) => {
    return (
      <ButtonWrapper key={index} record={record}>
        {lng('edit')}
      </ButtonWrapper>
    )
  }

  return keys?.map(key => ({
    title: lng(key) || key.toUpperCase(),
    dataIndex: key,
    key,
    ...(key === 'price' ? { render: PriceRender } : null),
    ...(key === 'actions' ? { render: ActionRender } : null),
    ...(key === 'kwh' ? { render: KwhRender } : null),
  }))
}
