import { Form, Button } from 'antd'
import moment from 'moment'
import CPOForm from '../../components/Form'
import { required } from '../../components/Form/validations'
import DateInput from '../../components/Form/Inputs/DateInput'
import CPOTable from '../../components/Table'
import { useStrings } from '../../hooks/useStrings'
import { createTableHeaders } from '../../utils/createTableHeaders'
import { useContext, useMemo, useState } from 'react'
import { useChargerSessions } from '../../hooks/ChargerSessions'
import { useEaseeItems } from '../../hooks/Item'
import { SettingsContext } from '../../contexts/Settings'

const FixedParking = () => {
  const { data: settings } = useContext(SettingsContext)
  const { lng } = useStrings('pages', 'FixedParkingWithUsers')
  const { data: items } = useEaseeItems()
  const [submittedValues, setSubmittedValues] = useState(null)

  const {
    data: sessions,
    mutateAsync: getSessions,
    isLoading,
    isError,
  } = useChargerSessions()

  const formattedSessions = useMemo(() => {
    if (sessions && !isLoading && !isError) {
      const formattedData = sessions?.map((session, i) => {
        const kwh =
          session?.ChargerSessions?.reduce(
            (acc, cSession) => acc + cSession?.kiloWattHours,
            0,
          ) ?? 0

        const item = items?.find(i => i?.id === session.ChargerId)

        return {
          key: i,
          chargerId: session.ChargerId,
          name: item?.name,
          price: Number(
            (kwh * Number(settings?.PriceSettings?.price ?? 0))?.toFixed(2),
          ),
          kwh,
        }
      })

      return formattedData
    }

    return null
  }, [sessions, settings, isLoading, isError])

  const headers = createTableHeaders(
    lng,
    ['name', 'chargerId', 'kwh', 'price'],
    settings,
  )

  const onSubmit = async values => {
    await getSessions(values)
    setSubmittedValues(values)
  }

  return (
    <div className="padding">
      <CPOForm onSubmit={onSubmit}>
        <div style={{ width: '400px' }}>
          <DateInput
            name="date"
            label={lng('timeRangeLabel')}
            validate={[required]}
            inputProps={{
              style: { width: '100%' },
              showTime: {
                defaultValue: [
                  moment('00:00:00', 'HH:mm:ss'),
                  moment('00:00:00', 'HH:mm:ss'),
                ],
                format: 'HH',
              },
            }}
          />
        </div>

        <Form.Item>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {lng('generateBtn')}
          </Button>
        </Form.Item>
      </CPOForm>

      <div>
        <CPOTable
          // exportTypes={['sbc']}
          summary={{
            keys: ['kwh', 'from', 'to', 'price'],
            date: {
              from: submittedValues?.date?.[0].format('YYYY-MM-DD HH:mm:ss'),
              to: submittedValues?.date?.[1].format('YYYY-MM-DD HH:mm:ss'),
            },
          }}
          data={formattedSessions}
          headers={headers}
        />
      </div>
    </div>
  )
}

export default FixedParking
