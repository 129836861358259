import {
  HomeOutlined,
  ClusterOutlined,
  LoginOutlined,
  SettingOutlined,
  TableOutlined,
  UserAddOutlined,
  UnorderedListOutlined,
  MailOutlined,
  PoweroffOutlined,
} from '@ant-design/icons'
import { languages } from '../../../contexts/Language'

import { CreateMenu, CreateRoutes, UserAvatar } from './helpers'

const _ProtectedPaths = [
  {
    path: '/',
    key: 'overview',
    icon: <HomeOutlined />,
    page: 'Overview',
  },
  {
    key: 'report',
    icon: <TableOutlined />,
    page: 'Reports',
    path: '/reports',
  },
  {
    path: '/accounts',
    key: 'listAccounts',
    icon: <UnorderedListOutlined />,
    page: 'Accounts',
  },
  {
    path: '/account/add',
    key: 'addAccount',
    icon: <UserAddOutlined />,
    page: 'AddAccount',
    hideInMenu: true,
  },
  {
    path: '/accounts/:id',
    key: 'updateAccount',
    icon: <UserAddOutlined />,
    page: 'UpdateAccount',
    hideInMenu: true,
  },
  {
    path: '/mailtemplate/:userId',
    key: 'mailTemplateId',
    icon: <MailOutlined />,
    page: 'MailTemplate',
    hideInMenu: true,
  },
  {
    path: '/charger/add',
    key: 'addCharger',
    icon: <PoweroffOutlined />,
    page: 'AddCharger',
    hideInMenu: true,
  },
  {
    path: '/chargers',
    key: 'chargers',
    icon: <PoweroffOutlined />,
    page: 'ListChargers',
  },
  {
    path: '/charger/:id',
    key: 'chargerid',
    icon: <ClusterOutlined />,
    page: 'Charger',
    hideInMenu: true,
  },
  {
    path: '/settings',
    key: 'settings',
    icon: <SettingOutlined />,
    page: 'Settings',
  },
  {
    path: '/login',
    key: 'login',
    icon: <LoginOutlined />,
    page: 'Login',
    hideInMenu: true,
    login: true,
  },
  {
    path: '/login/:admin/:username',
    key: 'login',
    icon: <LoginOutlined />,
    page: 'Login',
    hideInMenu: true,
    login: true,
  },
]

export const TopMenuItems = ({ user, lang, setLang }) => [
  user && {
    key: 'mail',
    icon: <UserAvatar user={user} size="small" marginRight="10px" />,
    style: { marginRight: 'auto' },
    label: user?.User?.Username,
  },
  {
    key: 'lang',
    label: lang?.langLong,
    children: languages?.map(_lang => ({
      key: _lang?.langShort,
      label: _lang?.langLong,
      onClick: setLang,
    })),
  },
]

export const SiderMenuItems = CreateMenu(
  _ProtectedPaths?.filter(path => !path?.hideInMenu),
)

export const RoutesItems = _ProtectedPaths
  ?.filter(route => !route?.login)
  ?.map(route => CreateRoutes(route))

export const LoginItems = _ProtectedPaths
  ?.filter(route => route?.login)
  ?.map(route => CreateRoutes(route))
