import { Button, Form, Switch } from 'antd'
import { Field } from 'react-final-form'
import { useParams } from 'react-router-dom'
import Error from '../../components/Error'
import CPOForm from '../../components/Form'
import StringInput from '../../components/Form/Inputs/StringInput'
import { emailValidation, required } from '../../components/Form/validations'
import Loading from '../../components/Loading'
import { useWelcomeMail } from '../../hooks/Mail'
import { useSubUser } from '../../hooks/SubUser'
import { useStrings } from '../../hooks/useStrings'

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

const Welcome = () => {
  const { userId } = useParams()
  const { lng } = useStrings('pages', 'WelcomeMail')

  const {
    data: subUser,
    isLoading: isSubUserLoading,
    isError: isSubUserError,
    error: subUserError,
    refetch: subUserRefetch,
  } = useSubUser(userId)

  const { mutateAsync: sendWelcome, isLoading: isMailLoading } =
    useWelcomeMail()

  const onSubmit = async values => {
    if (values.isTestMail) {
      await sendWelcome({ ...values, email: values.testMail })
    } else {
      await sendWelcome(values)
    }
  }

  if (userId && isSubUserLoading) return <Loading text={lng('loadingMsg')} />

  if (userId && isSubUserError)
    return (
      <Error
        text={subUserError}
        button={{ text: lng('tryAgain'), onClick: subUserRefetch }}
      />
    )

  return (
    <div>
      <CPOForm
        onSubmit={onSubmit}
        initialValues={
          userId
            ? {
                email: subUser?.UserInfo?.Email,
                password: process.env.REACT_APP_DEFAUlT_PASSWORD || '',
                name: `${subUser?.UserInfo?.FirstName} ${subUser?.UserInfo?.LastName}`,
                communityName: subUser?.UserInfo?.Notes?.CommunityName,
                subject: lng('subject'),
              }
            : {}
        }>
        <StringInput
          name="email"
          label={lng('emailLabel')}
          placeholder="john@doe.com"
          validate={[required, emailValidation]}
        />
        <StringInput
          name="password"
          label={lng('passwordLabel')}
          placeholder="********"
          validate={[required]}
          type={userId ? 'default' : 'password'}
          inputProps={{
            disabled: userId ? true : false,
          }}
        />
        <StringInput
          name="name"
          label={lng('nameLabel')}
          placeholder="John Doe"
          validate={[required]}
        />
        <StringInput
          name="communityName"
          label={lng('communityLabel')}
          validate={[required]}
        />
        <StringInput
          name="subject"
          label={lng('subjectLabel')}
          validate={[required]}
        />
        <Field
          name="isTestMail"
          render={({ input }) => (
            <Form.Item
              label={lng('isTestMailLabel')}
              valuePropName="checked"
              tooltip={{
                title: lng('isTestMailTooltip'),
              }}>
              <Switch onChange={input.onChange} checked={input.value} />
            </Form.Item>
          )}
        />
        <Condition when="isTestMail" is={true}>
          <StringInput
            name="testMail"
            label={lng('testMailLabel')}
            placeholder="john@doe.com"
            validate={[required, emailValidation]}
          />
        </Condition>
        <Form.Item>
          <Button
            loading={isMailLoading}
            style={{ float: 'right' }}
            type="primary"
            htmlType="submit">
            {lng('sendMailButton')}
          </Button>
        </Form.Item>
      </CPOForm>
    </div>
  )
}

export default Welcome
