import { Col, Row } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import Error from '../../../components/Error'
import Meta from '../../../components/Layout/Meta'
import Loading from '../../../components/Loading'
import { useEaseeItems } from '../../../hooks/Item'
import { useStrings } from '../../../hooks/useStrings'
import ChangeAuthCard from './ChangeAuthCard'
import ChargerPageHeader from './ChargerPageHeader'
import UpdateChargerName from './UpdateChargerName'
import UsersCard from './UsersCard'

const Charger = () => {
  const { id } = useParams()
  const { lng } = useStrings('pages', 'ChargerId')

  const {
    data: items,
    isLoading,
    isError,
    error: itemError,
    refetch,
  } = useEaseeItems()

  const item = items?.find(item => item?.id === id)

  if (isLoading) return <Loading text={lng('loadingMsg')} />

  if (isError)
    return (
      <Error
        text={itemError}
        button={{ text: lng('tryAgain'), onClick: refetch }}
      />
    )

  return (
    <div>
      <Meta title={lng('metaTitle')} />
      <ChargerPageHeader item={item} />
      <div className="padding">
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <UpdateChargerName item={item} />
            <ChangeAuthCard />
          </Col>
          <Col span={12}>
            <UsersCard item={item} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Charger
