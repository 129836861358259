import { Col, Row, Empty } from 'antd'
import { Descriptions } from 'antd'
import ChargerStatusWidget from './ChargerStatusWidget'
import Loading from '../../components/Loading'
import Error from '../../components/Error'
import { useStrings } from '../../hooks/useStrings'
import Meta from '../../components/Layout/Meta'
import { useEaseeItems } from '../../hooks/Item'
import { useState } from 'react'
import PageHeader from '../../components/Layout/PageHeader'

const updateTotalStates = (prev, id, val) => {
  const exists = prev?.some(item => item.id === id)

  if (!exists) {
    return [...prev, { id, val }]
  }

  const newTotalArray = prev?.map(item => {
    if (item.id === id) {
      return { id, val }
    }

    return item
  })

  return newTotalArray
}

const Overview = () => {
  const { lng } = useStrings('pages', 'Overview')

  const { data: items, isLoading, isError, error, refetch } = useEaseeItems()
  const [countCurrentTotal, setCountCurrentTotal] = useState([])
  const [countLatestTotal, setCountLatestTotal] = useState([])

  const handleSetCurrentTotal = (id, val) => {
    setCountCurrentTotal(prev => updateTotalStates(prev, id, val))
  }

  const handleSetLatestTotal = (id, val) => {
    setCountLatestTotal(prev => updateTotalStates(prev, id, val))
  }

  if (isLoading) return <Loading text={lng('loadingMsg')} />

  if (isError)
    return (
      <Error
        text={error}
        button={{ text: lng('tryAgain'), onClick: refetch }}
      />
    )

  return (
    <div>
      <Meta title={lng('metaTitle')} />

      <PageHeader pageHeaderProps={{ title: lng('metaTitle') }}>
        <Descriptions size="small" column={2}>
          <Descriptions.Item
            contentStyle={{ color: '#1890ff' }}
            label={lng('currLabel')}>
            {countCurrentTotal
              ?.reduce((acc, item) => acc + item?.val, 0)
              ?.toFixed(2) + ' kWh'}
          </Descriptions.Item>
          <Descriptions.Item
            contentStyle={{ color: '#1890ff' }}
            label={lng('lastLabel')}>
            {countLatestTotal
              ?.reduce((acc, item) => acc + item?.val, 0)
              ?.toFixed(2) + ' kWh'}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <div className="padding">
        <Row gutter={[16, 16]}>
          {items?.map((item, i) => (
            <Col key={i}>
              <ChargerStatusWidget
                item={item}
                handleSetCurrentTotal={handleSetCurrentTotal}
                handleSetLatestTotal={handleSetLatestTotal}
              />
            </Col>
          ))}
        </Row>
        {(!items || items?.length === 0) && <Empty />}
      </div>
    </div>
  )
}

export default Overview
