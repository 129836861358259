import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { handleTypes } from '../components/Error'
import Message from '../components/Message'
import fetchJson from '../utils/fetchJson'
import { useAuth } from './Auth'
import { useEaseeItems, useSubUserItems } from './Item'
import { useStrings } from './useStrings'

export const rfIdKey = 'rfId'

export const fetchRfIdTags = (ids, user) => {
  return async () =>
    await fetchJson(
      `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/easeehome/bulkgetrfidtags`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization': user?.TokenInfo?.Token,
        },
        body: JSON.stringify({
          ChargerIds: ids,
        }),
      },
    )
}

export const useRfIdTags = () => {
  const { user } = useAuth()
  const { data: items } = useEaseeItems()

  const ids = items?.map(item => item?.id) || []

  return useQuery([rfIdKey], fetchRfIdTags(ids, user), {
    enabled: ids?.length > 0 && !!user,
  })
}

export const useUserRfIdTags = id => {
  const { data: tags, isLoading, isError, ...rest } = useRfIdTags()

  const data = useMemo(() => {
    if (tags && !isLoading && !isError) {
      const formattedData = tags?.reduce((acc, charger) => {
        for (const tag of charger.RfidTags) {
          if (tag?.EndUserId === Number(id)) {
            const index = acc?.findIndex(_tag => _tag?.Rfid === tag?.Rfid)

            if (index > -1) {
              acc[index] = {
                ...acc[index],
                ChargerId: [...acc[index].ChargerId, tag?.ChargerId],
              }
            } else {
              acc.push({
                ...tag,
                ChargerId: [tag?.ChargerId],
              })
            }
          }
        }

        return acc
      }, [])

      return formattedData
    }
  }, [tags, isLoading, isError])

  return { data, isLoading, isError, ...rest }
}

export const useCreateRfIdTags = id => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const { lng } = useStrings('components', 'hookMessages')
  const { data: items } = useSubUserItems(id)

  const ids = items?.map(item => item?.id) || []

  return useMutation(
    async ({ values, userId }) => {
      const tagsArr = ids?.map(chargerId => ({
        ChargerId: chargerId,
        Rfid: values?.rfId,
        UserId: user?.User?.UserId,
        EndUserId: userId,
        Name: values?.name || '',
      }))

      return await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/easeehome/bulkloadrfidtags`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify({
            RfidTags: tagsArr,
          }),
        },
      )
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async (_, variables) => {
        await queryClient.refetchQueries([rfIdKey], {
          active: true,
          exact: true,
        })

        Message(`${lng('createSuccess')} ${variables?.values?.name}`, 'success')
      },
    },
  )
}

export const useDeleteRfIdTag = () => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const { lng } = useStrings('components', 'hookMessages')

  return useMutation(
    async values => {
      const tagsArr = Array.isArray(values.chargers)
        ? values?.chargers?.map(chargerId => ({
            ChargerId: chargerId,
            Rfid: values?.rfId,
          }))
        : [
            {
              ChargerId: values?.chargers,
              Rfid: values?.rfId,
            },
          ]

      return await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/easeehome/bulkdeleterfidtags`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify({
            RfidTags: tagsArr,
          }),
        },
      )
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async (_, variables) => {
        await queryClient.refetchQueries([rfIdKey], {
          active: true,
          exact: true,
        })

        Message(`${lng('deleteSuccess')} ${variables?.rfId}`, 'success')
      },
    },
  )
}
