import RadioInput from '../../components/Form/Inputs/RadioInput'
import StringInput from '../../components/Form/Inputs/StringInput'
import { useStrings } from '../../hooks/useStrings'

const PriceSettings = () => {
  const { lng } = useStrings('pages', 'Settings')

  const options = [
    { label: lng('prefixLabel'), value: 'prefix' },
    { label: lng('suffixLabel'), value: 'suffix' },
  ]

  return (
    <>
      <StringInput
        name="PriceSettings.price"
        label={lng('priceLabel')}
        placeholder="0,00"
        type="number"
        inputProps={{
          type: 'number',
        }}
      />
      <StringInput
        name="PriceSettings.currency"
        label={lng('currencyLabel')}
        placeholder={lng('currencyPlaceholder')}
      />
      <RadioInput
        name="PriceSettings.currencyFix"
        label={lng('currencyFixLabel')}
        options={options}
      />
    </>
  )
}

export default PriceSettings
