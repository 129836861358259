import { useContext } from 'react'
import { useMutation } from 'react-query'
import { handleTypes } from '../components/Error'
import Message from '../components/Message'
import { LanguageContext } from '../contexts/Language'
import fetchJson from '../utils/fetchJson'
import { useStrings } from './useStrings'

export const useWelcomeMail = () => {
  const { lang } = useContext(LanguageContext)
  const { lng } = useStrings('pages', 'WelcomeMail')

  return useMutation(
    async values => {
      return await fetchJson('/api/sendgrid/welcome', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...values,
          lang: lang?.langShort || 'se',
        }),
      })
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async (_, variables) => {
        Message(`${lng('successMsg')} ${variables?.email}`, 'success')
      },
    },
  )
}
