import { Card, Switch, Typography } from 'antd'
import { useParams } from 'react-router-dom'
import {
  useChargerConfig,
  useUpdateChargerConfig,
} from '../../../hooks/Charger'

import { useStrings } from '../../../hooks/useStrings'

const ChangeAuthCard = () => {
  const { id } = useParams()
  const { lng } = useStrings('pages', 'ChargerId')

  const { data: auth, isLoading, isError } = useChargerConfig(id)
  const { mutate, isLoading: updateLoading } = useUpdateChargerConfig(id)

  const changeAuth = async checked => {
    mutate({ id, required: checked })
  }

  return (
    <Card style={{ marginTop: '32px' }} title={lng('enableAuthentication')}>
      <Switch
        disabled={isError}
        checkedChildren={lng('authOn')}
        unCheckedChildren={lng('authOff')}
        checked={auth?.ChargerConfig?.authorizationRequired}
        loading={isLoading || updateLoading}
        onChange={changeAuth}
      />
      <Typography style={{ marginTop: '20px' }}>
        {lng('authDescription')}
      </Typography>
    </Card>
  )
}

export default ChangeAuthCard
