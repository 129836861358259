import { Button, Form } from 'antd'
import Error from '../../components/Error'
import CPOForm from '../../components/Form'
import StringInput from '../../components/Form/Inputs/StringInput'
import { emailValidation } from '../../components/Form/validations'
import Loading from '../../components/Loading'
import { useGetAdminInfo, useUpdateAdminInfo } from '../../hooks/Auth'
import { useStrings } from '../../hooks/useStrings'

const AdminSettings = () => {
  const { lng } = useStrings('pages', 'Settings')
  const {
    data: adminInfo,
    isLoading,
    isError,
    isFetched,
    error,
  } = useGetAdminInfo()
  const { mutateAsync, isLoading: isUpdateLoading } = useUpdateAdminInfo()

  const onSubmit = async values => {
    await mutateAsync(values)
  }

  if (isLoading || !isFetched) return <Loading text={lng('loadingMsg')} />

  if (isError) return <Error text={error} button={{ text: lng('tryAgain') }} />

  return (
    <CPOForm initialValues={adminInfo} onSubmit={onSubmit}>
      <StringInput
        name="Email"
        label={lng('emailLabel')}
        placeholder="john@doe.com"
        validate={[emailValidation]}
      />
      <StringInput
        name="FirstName"
        label={lng('firstNameLabel')}
        placeholder="John"
      />
      <StringInput
        name="LastName"
        label={lng('lastNameLabel')}
        placeholder="Doe"
      />
      <StringInput
        name="Address"
        label={lng('addressLabel')}
        placeholder="Johndoestreet 1"
      />
      <StringInput
        name="ZipCode"
        label={lng('zipCodeLabel')}
        placeholder="111 22"
      />
      <StringInput
        name="City"
        label={lng('cityLabel')}
        placeholder="Stockholm"
      />
      <StringInput
        name="PhoneNumber"
        label={lng('phoneLabel')}
        placeholder="070 123 45 67"
      />
      <StringInput name="Organization" label={lng('organizationLabel')} />
      <Form.Item>
        <Button
          loading={isUpdateLoading}
          style={{ marginTop: '10px', float: 'right' }}
          type="primary"
          htmlType="submit">
          {lng('saveSettings')}
        </Button>
      </Form.Item>
    </CPOForm>
  )
}

export default AdminSettings
