const createHeaders = ({ headers, childHeaders, length }) => {
  const defaultHeaders = headers?.map(col => col.title) || []

  if (childHeaders) {
    return [
      ...defaultHeaders,
      ...[...Array(length).keys()].reduce((acc, childKey) => {
        childHeaders.forEach(child => {
          acc.push(`${child?.title}${childKey + 1}`)
        })

        return acc
      }, []),
    ]
  }

  return defaultHeaders
}

// Finds the longest child
const findLengthOfChildren = data => {
  let length = 0
  for (let i = 0; i < data.length; i++) {
    const row = data[i]

    if (row?.subTable?.length > length) {
      length = row?.subTable?.length
    }
  }

  return length
}

const switchObjectKeys = ({ row, headers }) => {
  // New flat object { ...props, children: [{ ...props }] } => { ...props, ...childProps }
  const newRow = {}

  // Loop thru keys of row
  for (const key of Object.keys(row || {})) {
    // If children loop thru and call function again to change keys
    if (key === 'subTable') {
      for (let i = 0; i < row?.[key]?.length; i++) {
        const child = row?.[key]?.[i]
        const newChild = switchObjectKeys({ row: child, headers })
        for (const childKey of Object.keys(newChild || {})) {
          // Add a number to each key, so that duplicates gets a unique key.
          newRow[`${childKey}${i + 1}`] = newChild[childKey]
        }
      }
    }

    // headers contains readable versions of the keys
    const header = headers?.find(col => col?.dataIndex === key)

    // Filters out the 'key' key and any key that does not have a readable value
    if (header) {
      // Assign the value to the new key
      switch (key) {
        case 'kwh':
          newRow[header?.title] =
            Number(row?.[key])?.toFixed?.(6)?.toString()?.replace('.', ',') ??
            ''
          break
        case 'price':
          newRow[header?.title] =
            Number(row?.[key])?.toFixed?.(2)?.toString()?.replace('.', ',') ??
            ''
          break
        default:
          newRow[header?.title] = row[key]
          break
      }
    }
  }

  return newRow
}

export const createCSVData = ({
  data,
  headers,
  childHeaders,
  // settings,
  extra,
}) => {
  if (!data) return []

  // Creates an array of strings representing headers in the CSV data
  const csvHeaders = createHeaders({
    headers,
    childHeaders,
    length: findLengthOfChildren(data),
  })

  // Recursive function switching keys and flattening data
  const dataWithNewKeys = data?.map(row => {
    return switchObjectKeys({ row, headers: [...headers, ...childHeaders] })
  })

  // Creates an array of arrays of strings => [["string", "string"], ["string", "string"]]
  // Matches csvHeaders and DataWithNewKeys
  const flatRows = dataWithNewKeys?.map(row => {
    return csvHeaders?.map(header => row?.[header] ?? '')
  })

  return [...extra, [], csvHeaders, ...flatRows]
}
