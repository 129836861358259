import { Button, Card, Form } from 'antd'
import { useParams } from 'react-router-dom'
import CPOForm from '../../../components/Form'
import StringInput from '../../../components/Form/Inputs/StringInput'
import { emailValidation, required } from '../../../components/Form/validations'
import { useUpdateSubUser } from '../../../hooks/SubUser'
import { useStrings } from '../../../hooks/useStrings'

const UserInfo = ({ subUser = {} }) => {
  const { id } = useParams()
  const { lng } = useStrings('pages', 'UpdateAccount')

  const { mutate, isLoading } = useUpdateSubUser(id)

  const onSubmit = values => {
    mutate({ id, ...values })
  }

  return (
    <Card title={lng('userInfo')}>
      <CPOForm
        onSubmit={onSubmit}
        initialValues={{
          username: subUser?.User?.Username,
          email: subUser?.UserInfo?.Email,
          firstName: subUser?.UserInfo?.FirstName,
          lastName: subUser?.UserInfo?.LastName,
          address: subUser?.UserInfo?.Address,
          zipCode: subUser?.UserInfo?.ZipCode,
          city: subUser?.UserInfo?.City,
          phoneNumber: subUser?.UserInfo?.PhoneNumber,
          apartmentNumber: subUser?.UserInfo?.Notes?.ApartmentNumber,
          communityName: subUser?.UserInfo?.Notes?.CommunityName,
        }}>
        <StringInput
          name="email"
          label={lng('emailLabel')}
          placeholder="john@doe.com"
          validate={[emailValidation]}
        />
        <StringInput
          name="username"
          label={lng('usernameLabel')}
          placeholder={lng('usernamePlaceholder')}
          validate={[required]}
        />
        <StringInput
          name="firstName"
          label={lng('firstNameLabel')}
          placeholder="John"
        />
        <StringInput
          name="lastName"
          label={lng('lastNameLabel')}
          placeholder="Doe"
        />
        <StringInput
          name="phoneNumber"
          label={lng('phoneLabel')}
          placeholder="070 123 45 67"
        />
        <StringInput name="apartmentNumber" label={lng('apartmentLabel')} />
        <StringInput
          name="address"
          label={lng('addressLabel')}
          placeholder="Johndoestreet 1"
        />
        <StringInput
          name="zipCode"
          label={lng('zipCodeLabel')}
          placeholder="111 22"
        />
        <StringInput
          name="city"
          label={lng('cityLabel')}
          placeholder="Stockholm"
        />
        <StringInput
          FormItemProps={{ noStyle: true }}
          inputProps={{ type: 'hidden' }}
          name="communityName"
          label={lng('communityLabel')}
        />
        <Form.Item>
          <Button
            loading={isLoading}
            style={{ float: 'right' }}
            type="primary"
            htmlType="submit">
            {lng('updateUserButton')}
          </Button>
        </Form.Item>
      </CPOForm>
    </Card>
  )
}

export default UserInfo
