export const initialValuesForAntD = initialValues => {
  const newInitialValues = Object.keys(initialValues || {}).reduce(
    (acc, key) => {
      const val = initialValues[key]

      if (typeof val === 'object') {
        Object.keys(val || {}).forEach(childKey => {
          const childVal = val[childKey]

          acc[`${key}.${childKey}`] = childVal
        })
      } else {
        acc[key] = val
      }

      return acc
    },
    {},
  )

  return newInitialValues
}
