import { useMutation, useQuery, useQueryClient } from 'react-query'
import { handleTypes } from '../components/Error'
import Message from '../components/Message'

import fetchJson from '../utils/fetchJson'
import { useAuth } from './Auth'
import { useStrings } from './useStrings'

const keyAuth = 'chargerAuth'
const keyState = 'chargerState'

export const useChargerConfig = id => {
  const { user } = useAuth()

  return useQuery(
    [keyAuth, id],
    async () =>
      await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/easeehome/getchargerconfig/${id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
        },
      ),
    {
      enabled: !!id && !!user,
    },
  )
}

export const useChargerState = id => {
  const { user } = useAuth()

  return useQuery(
    [keyState, id],
    async () =>
      await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/easeehome/getchargerstate/${id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/*+json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
        },
      ),
    {
      enabled: !!id && !!user,
    },
  )
}

export const useUpdateChargerConfig = id => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { lng } = useStrings('components', 'hookMessages')

  return useMutation(
    async values => {
      return await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/easeehome/setauthorization/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify({
            ChargerId: values?.id,
            AuthorizationRequired: values?.required,
          }),
        },
      )
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async (_, variables) => {
        await queryClient.refetchQueries([keyAuth, id], {
          active: true,
          exact: true,
        })

        Message(`${lng('updateSuccess')} ${variables?.id}`, 'success')
      },
    },
  )
}

export const useAddCharger = () => {
  const { user } = useAuth()
  const { lng } = useStrings('components', 'hookMessages')
  const queryClient = useQueryClient()

  return useMutation(
    async values => {
      const data = {
        UserId: user?.User?.UserId,
        Name: values?.name,
        ItemType: 'Charger',
        ItemSubType: 'EaseeHomeCharger',
        ItemCategory: 'RemoteVirtual',
        MacAddress: '00:00:00:00:00:00',
        TimeZone: 'Europe/Stockholm',
        UserSettings: JSON.stringify({
          easee: {
            siteid: '',
            chargers: [
              {
                name: 'Laddbox',
                chargerid: values?.serialNumber,
                chargerpin: '0000',
                isPermanentlyLocked: false,
              },
            ],
            circuitid: '',
            circuitname: '',
            nActiveChargers: '',
          },
        }),
      }

      return await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/addchargeritem`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify(data),
        },
      )
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async (_, variables) => {
        await queryClient.refetchQueries('items', {
          active: true,
          exact: true,
        })

        Message(`${lng('createSuccess')} ${variables?.serialNumber}`, 'success')
      },
    },
  )
}

export const useUpdateCharger = () => {
  const { user } = useAuth()
  const { lng } = useStrings('components', 'hookMessages')
  const queryClient = useQueryClient()

  return useMutation(
    async values => {
      return await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/setitemname`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify(values),
        },
      )
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async (_, variables) => {
        await queryClient.refetchQueries(['items'], {
          active: true,
          exact: true,
        })

        Message(`${lng('updateSuccess')} ${variables?.Name}`, 'success')
      },
    },
  )
}
