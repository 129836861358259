import React from 'react'
import { useParams } from 'react-router-dom'
import CPOTabs from '../../components/CPOTabs'
import Meta from '../../components/Layout/Meta'
import PageHeader from '../../components/Layout/PageHeader'
import { useStrings } from '../../hooks/useStrings'
import Welcome from './Welcome'

const MailTemplate = () => {
  const { lng } = useStrings('pages', 'MailTemplate')
  const { userId } = useParams()

  const tabs = [
    {
      label: lng('welcome'),
      component: <Welcome />,
    },
  ]

  return (
    <div>
      <Meta title={lng('metaTitle')} />
      <PageHeader
        backTo={userId ? `/accounts/${userId}` : '/accounts'}
        pageHeaderProps={{ title: lng('metaTitle') }}
      />
      <div className="padding">
        <CPOTabs tabs={tabs} />
      </div>
    </div>
  )
}

export default MailTemplate
