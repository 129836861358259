import { formatISO } from 'date-fns'
import { useQuery } from 'react-query'
import fetchJson from '../utils/fetchJson'
import { useAuth } from './Auth'

const chargerKey = 'chargerEvents'

export const useEventsByCharger = id => {
  const { user } = useAuth()

  return useQuery(
    [chargerKey, id],
    async () =>
      await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/cpo/geteventsbychargerid`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
          body: JSON.stringify({
            ChargerId: id,
          }),
        },
      ),
    {
      enabled: !!id && !!user,
    },
  )
}

export const eventKey = 'events'

export const fetchEvents = (from, to, user) => {
  return async () => {
    return await fetchJson(
      `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/cpo/getactivationevents`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization': user?.TokenInfo?.Token,
        },
        body: JSON.stringify({
          UserId: user?.User.UserId,
          From: from,
          To: to,
        }),
      },
    )
  }
}

export const useEvents = values => {
  const { user } = useAuth()
  const from = formatISO(new Date(values?.date[0]))
  const to = formatISO(new Date(values?.date[1]))

  return useQuery(eventKey, fetchEvents(from, to, user), {
    enabled: !!to && !!from && !!user,
  })
}
