import { Button, Form } from 'antd'
import { useParams } from 'react-router-dom'
import CPOForm from '../../../../components/Form'
import StringInput from '../../../../components/Form/Inputs/StringInput'
import { required } from '../../../../components/Form/validations'
import { useCreateRfIdTags } from '../../../../hooks/RfId'
import { useStrings } from '../../../../hooks/useStrings'

const CreateRfId = () => {
  const { id } = useParams()
  const { lng } = useStrings('pages', 'UpdateAccount')

  const { mutateAsync: createTag, isLoading } = useCreateRfIdTags(id)

  const onSubmit = async values => {
    await createTag({
      values,
      userId: id,
    })
  }

  return (
    <CPOForm onSubmit={onSubmit} resetOnSubmit>
      <StringInput
        name="rfId"
        label="RFID"
        placeholder="01234A12345678"
        validate={[required]}
      />
      <StringInput name="name" label={lng('name')} placeholder="Tag 1" />
      <Form.Item>
        <Button
          loading={isLoading}
          style={{ float: 'right' }}
          type="primary"
          htmlType="submit">
          {lng('addRfIdTagButton')}
        </Button>
      </Form.Item>
    </CPOForm>
  )
}

export default CreateRfId
