import { ConfigProvider } from 'antd'
import React, { createContext, useEffect, useState } from 'react'

import enUS from 'antd/lib/locale/en_US'
import svSE from 'antd/lib/locale/sv_SE'
import 'moment/locale/sv'
import moment from 'moment'

moment.locale('en')

export const languages = [
  {
    langShort: 'en',
    langLong: 'English',
    langFileRemote: '',
    langFileLocal: async () => await import('../lang/lang_cpo_en.json'),
    moment: () => moment.locale('en'),
    antd: enUS,
    flag: (
      <img alt="en" style={{ marginRight: '7px' }} src="/media/ukflag.png" />
    ),
  },
  {
    langShort: 'se',
    langLong: 'Svenska',
    langFileRemote: '',
    langFileLocal: async () => await import('../lang/lang_cpo_se.json'),
    moment: () => moment.locale('sv'),
    antd: svSE,
    flag: (
      <img alt="se" style={{ marginRight: '7px' }} src="/media/sweflag.png" />
    ),
  },
]

export const LanguageContext = createContext()

const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState()
  const [strings, setStrings] = useState()

  useEffect(() => {
    const langKey = localStorage.getItem('lang')
    if (langKey) {
      handleSetLang({ key: langKey })
    } else {
      handleSetLang({ key: 'en' })
    }
  }, [])

  const handleSetLang = async ({ key }) => {
    localStorage.setItem('lang', key)

    const newLang = languages.find(language => language?.langShort === key)

    setLang(newLang)

    const langFile = await newLang.langFileLocal()
    newLang.moment()

    setStrings(langFile)
  }

  return (
    <LanguageContext.Provider value={{ lang, setLang: handleSetLang, strings }}>
      <ConfigProvider locale={lang?.antd}>
        <React.Fragment key={lang ? lang?.langShort : ''}>
          {children}
        </React.Fragment>
      </ConfigProvider>
    </LanguageContext.Provider>
  )
}

export default LanguageProvider
